import React, { Component } from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import { bpsSDStrategy, bpsSDBranding, bpsSDWeb, bpsSDSoftware } from '../../Shared/Constants/breakpoints'

import ServicePreview from './ServicePreview'
import media from '../../../theme/DeviceWidth'
import FLC from '../../Shared/FLC'
import Quote from './Quote'

import StrategyBrainWhite from '../../../assets/icons/strategyBrain_white.svg'
import StrategyBrainDark from '../../../assets/icons/strategyBrain_dark.svg'
import BrandingPaletteWhite from '../../../assets/icons/brandingPalette_white.svg'
import BrandingPaletteDark from '../../../assets/icons/brandingPalette_dark.svg'
import WebLayoutWhite from '../../../assets/icons/webLayout_white.svg'
import WebLayoutDark from '../../../assets/icons/webLayout_dark.svg'
import SoftwareWhite from '../../../assets/icons/software_white.svg'
import SoftwareDark from '../../../assets/icons/software_dark.svg'


const RelativeContainer = styled.div`
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.tablet`
        margin-bottom: -40px;
    `}

    ${media.laptop`
        margin-bottom: -70px;
    `}
`

const GridContainer = styled.div`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.tablet`
        display: grid;
        grid-template-columns: auto auto ;
    `}

    ${media.laptop`
        padding-left: 120px;
        padding-right: 60px;
    `}

    ${media.laptopL`
        padding-left: 200px;
        padding-right: 140px;
    `}
`

const VertSpacer = styled.div`
    height: 40px; 
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.laptop`
        height: 60px; 
    `}
`

const QuoteDiv = styled.div`
    width: 100%;
    background: ${props => props.theme.colors.fontDark};
    color: ${props => props.theme.colors.backgroundLight};
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    font-size: 1em;
`


class ServicesDisplay extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLaptop: false
        }
    }

    setWindow = () => {
        if (window.innerWidth > 1000) {
            this.setState({
                isLaptop: true
            })
        }
    }

    //  perhaps need to throttle this for performance, import throttle from 'lodash.throttle'; but also nobody should actually be resizing this unless your a dev checking on things
    handleWindowResize = (y) => {
        this.setState({
            isLaptop: y
        })
    }

    onResize = () => {
        if (window.innerWidth > 1000) {
            this.handleWindowResize(true)
        } else {
            this.handleWindowResize(false)
        }
    }

    componentDidMount() {
        this.setWindow()
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
    }

    render() {
        return (
            <RelativeContainer>
                <GridContainer>
                    <Fade up duration={2100}>
                        <ServicePreview
                            srcWhite={StrategyBrainWhite}
                            altWhite='Brain Strategy Icon White Color'
                            srcDark={StrategyBrainDark}
                            altDark='Brain Strategy Icon Black Color'
                            header='Strategy'
                            item1='Market Research'
                            item2='Integrated Marketing'
                            item3='Analysis & Optimization'
                            item4='Content Marketing & Hubspot'
                            item5='Digital (SEO, SEM, PPC, Social)'
                            leftSpan='140px'
                            bottomSpan='302px'
                            fontSize='1.3em'
                            heightWhite='48px'
                            widthWhite='48px'
                            leftWhite='10px'
                            topWhite='-200px'
                            heightDark='48px'
                            widthDark='48px'
                            leftDark='-38px'
                            topDark='-200px'
                            breakpoints={bpsSDStrategy}
                            to='/strategy' />
                        <ServicePreview
                            srcWhite={BrandingPaletteWhite}
                            altWhite='Branding painters pallete icon white color'
                            srcDark={BrandingPaletteDark}
                            altDark='Branding painters pallete icon black color'
                            header='Branding'
                            item1='Influencer Outreach'
                            item2='Brand Development'
                            item3='Naming & Messaging'
                            item4='Logo & Visual Identity'
                            item5='Rebranding & Style Guides'
                            item6='Collateral, Print, & Packaging'
                            leftSpan='140px'
                            bottomSpan='302px'
                            fontSize='1.3em'
                            heightWhite='48px'
                            widthWhite='48px'
                            leftWhite='12px'
                            topWhite='-200px'
                            heightDark='48px'
                            widthDark='48px'
                            leftDark='-38px'
                            topDark='-200px'
                            breakpoints={bpsSDBranding}
                            to='/branding' />
                        <ServicePreview
                            srcWhite={WebLayoutWhite}
                            altWhite='Website Layout icon white color'
                            srcDark={WebLayoutDark}
                            altDark='Website Layout icon black color'
                            header='Web'
                            item1='Responsive Focus'
                            item2='QA and User Testing'
                            item3='User Experience (UX)'
                            item4='E-commerce and CRM'
                            item5='Content Management Systems'
                            leftSpan='140px'
                            bottomSpan='290px'
                            fontSize='1.3em'
                            heightWhite='48px'
                            widthWhite='48px'
                            leftWhite='10px'
                            topWhite='-200px'
                            heightDark='48px'
                            widthDark='48px'
                            leftDark='-38px'
                            topDark='-200px'
                            breakpoints={bpsSDWeb}
                            to='/web' />
                        <ServicePreview
                            srcWhite={SoftwareWhite}
                            altWhite='software icon white color'
                            srcDark={SoftwareDark}
                            altDark='software icon white color'
                            header='Software'
                            item3='Mobile Applications'
                            item2='User interface (UI)'
                            item1='Custom Solutions'
                            leftSpan='140px'
                            bottomSpan='260px'
                            fontSize='1.3em'
                            heightWhite='48px'
                            widthWhite='48px'
                            leftWhite='10px'
                            topWhite='-200px'
                            heightDark='48px'
                            widthDark='48px'
                            leftDark='-38px'
                            topDark='-200px'
                            breakpoints={bpsSDSoftware}
                            to='/software' />
                    </Fade>
                </GridContainer>
                <VertSpacer></VertSpacer>
                <FLC />
                {this.state.isLaptop
                    ? <QuoteDiv>
                            <Fade duration={2000}>
                                <Quote quotes={this.props.quotes} />
                            </Fade>
                        </QuoteDiv>
                    : <div></div>
                }
            </RelativeContainer>
        )
    }
}

export default ServicesDisplay;