import React from 'react'
import styled from 'styled-components/macro'
import Helmet from 'react-helmet'

import media from '../../../theme/DeviceWidth'
import ServicesDisplay from './ServicesDisplay'
import { ServicesDescription } from './QuoteText'
import { MetaServices } from '../../Shared/Constants/meta'
import { BrandingQuoteList } from './QuoteText'

import JpCreativeBrain from '../../../assets/images/jp_creativeBrain_opt.jpg'

const RelativeContainer = styled.div`
    position: relative;
`

const HeroImg = styled.img`
    width: 100%;
    position: relative;

    ${media.phoneS`
        top: 90px;
    `}
        
    ${media.phoneM`
        top: 100px;
    `}
            
    ${media.tablet`
        top: 120px;    
    `}
`

const HeroTitle = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .06em;
    font-weight: 500;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    bottom: 10px;

    ${media.phoneS`
        font-size: 1.5em;
        padding: 110px 0px 10px 20px;
    `}
        
    ${media.phoneM`
        font-size: 1.65em;
        padding: 110px 0px 10px 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.75em;
        padding: 110px 0px 12px 22px;      
    `}
            
    ${media.tablet`
        font-size: 2em;
        padding: 140px 0px 12px 32px;         
    `}
        
    ${media.laptop`
        font-size: 2.1em;
        padding: 140px 100px 12px 100px;           
    `}
        
    ${media.laptopL`
        padding: 140px 120px 12px 120px;       
    `}
`

const HeroDescription = styled.div`
    line-height: 1.25;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.1em;
        padding: 0px 20px 10px 20px;
    `}
        
    ${media.phoneM`
        font-size: 1.2em;
        padding: 0px 20px 10px 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.3em;
        padding: 0px 22px 10px 23px;      
    `}
            
    ${media.tablet`
        font-size: 1.5em;
        padding: 0px 32px 10px 33px;         
    `}
        
    ${media.laptop`
        font-size: 1.6em;
        padding: 0px 100px 20px 100px;        
    `}
        
    ${media.laptopL`
        padding: 0px 120px 30px 120px;         
    `}

    ${media.laptopLL`
        padding: 0px 144px 30px 120px;         
    `}
`

//eventually put customer quotes down there and maybe a link to the blog somehow creative

function HeroBody() {
    return (
        <RelativeContainer>
            <Helmet titleTemplate='Services | %s'>
                <title>{MetaServices.titleServicesFull}</title>
                <meta name='description' content={MetaServices.description}></meta>
                <meta name='keywords' content={MetaServices.keywords}></meta>
            </Helmet>
            <HeroImg src={JpCreativeBrain} alt='Brain with creative ideas flowing' />
            <HeroTitle>{ServicesDescription.title}</HeroTitle>
            <HeroDescription>{ServicesDescription.description1}</HeroDescription>
            <ServicesDisplay quotes={BrandingQuoteList} />
        </RelativeContainer>
    )
}

export default HeroBody;