import React from 'react'
import styled from 'styled-components/macro'
import Particles from 'react-particles-js'
import { withBps } from 'react-bps'

import { Service } from '../../Shared/Constants/particleOptionParameters'

import media from '../../../theme/DeviceWidth'
import Icon from '../../Shared/Icon'
import ServiceLink from './ServiceLink'
import styleConstants from '../../../theme/styleConstants'

const ParticleDiv = styled.div`
    background: ${props => props.theme.colors.fontDark};
    width: 100%;
    height: 230px;
    z-index: -1;

    ${media.phoneM`
        height: 240px;
    `}

    ${media.phoneL`
        height: 260px;
    `}

    ${media.tablet`
        height: 270px;
    `}
`

const Container = styled.div`
    margin: 20px 0px 30px 20px;
    height: 220px;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.16);
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    width: 290px;

    :hover .serviceOverlay  {
        display: none;
    }

    :hover .servicesDarkIcon  {
        display: none;
    }
    
    :hover .servicesArrowSpan  {
        color: ${styleConstants.colors.backgroundLight} !important;
    }

    :hover .servicesHeader  {
        color: ${styleConstants.colors.backgroundLight} ;
    }

    :hover .servicesList  {
        color: ${styleConstants.colors.backgroundLight} ;
    }

    ${media.phoneSM`
        width: 310px;
    `}
        
    ${media.phoneM`
        width: 338px;
    `}
            
    ${media.phoneL`
        width: 372px;
        height: 250px;       
    `}
            
    ${media.tablet`
        width: 372px;
        height: 270px;       
    `}
        
    ${media.laptop`
        width: 400px;    
    `}
`

const Header = styled.div`
    color: ${props => props.theme.colors.fontDark};
    position: relative;
    z-index: 2;
`

const HeaderP = styled.p`
    position: relative;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .07em;
    font-weight: 300;
    z-index: 2;


    ${media.phoneS`
        top: -280px;
        left: 70px;
        font-size: 1.4em;
    `}
        
    ${media.phoneM`
        top: -304px;
        left: 84px;
        font-size: 1.5em;
    `}
            
    ${media.phoneL`
        top: -324px;
        left: 94px;
        font-size: 1.6em;     
    `}
`

const ServiceList = styled.ul`
    color: ${props => props.theme.colors.fontDark};
    position: relative;
    list-style-type: none;
    z-index: 2;

    ${media.phoneS`
        top: -300px;
        left: 30px;
    `}
        
    ${media.phoneM`
        top: -329px;
        left: 54px;
    `}
            
    ${media.phoneL`
        top: -349px;
        left: 64px;    
    `}
`

const ListItem = styled.li`
    line-height: 1.65em;
    letter-spacing: 0.11em;
    z-index: 2;

    ${media.phoneS`
        font-size: 0.78em;
    `}
        
    ${media.phoneM`
        font-size: 0.88em;
        
    `}
            
    ${media.phoneL`
        font-size: 0.97em;      
    `}
`

const LearnDiv = styled.div`
    position: relative;
    z-index: 2;
    color: #fff ;
`

const Overlay = styled.div`
    background: #fff;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 380px;
`

function ServicePreview(props) {
    return (
        <Container>
            <ParticleDiv>
                <Particles width='90%' height='36vh' params={Service} />
            </ParticleDiv>
            <Header>
                <Icon zIndex='1'
                    src={props.srcWhite}
                    alt={props.altWhite}
                    height={props.heightWhite}
                    width={props.widthWhite}
                    left={props.leftWhite}
                    top={props.topWhite} />
                <Icon zIndex='3' className='servicesDarkIcon' 
                    src={props.srcDark}
                    alt={props.altDark}
                    height={props.heightDark}
                    width={props.widthDark}
                    left={props.leftDark}
                    top={props.topDark} />
                <HeaderP className='servicesHeader'>{props.header}</HeaderP>
            </Header>
            <ServiceList className='servicesList'>
                <ListItem>{props.item1}</ListItem>
                <ListItem>{props.item2}</ListItem>
                <ListItem>{props.item3}</ListItem>
                <ListItem>{props.item4}</ListItem>
                <ListItem>{props.item5}</ListItem>
                <ListItem>{props.item6}</ListItem>
                <ListItem>{props.item7}</ListItem>
            </ServiceList>
            <LearnDiv>
                <ServiceLink spanText='LEARN MORE' letterSpacing='1.4px'
                    to={props.to}
                    fontSize={props.fontSize}
                    left={props.leftSpan}
                    bottom={props.bottomSpan}
                    color={styleConstants.colors.greenMain} />
            </LearnDiv>
            <Overlay className='serviceOverlay'></Overlay>
        </Container>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(ServicePreview);