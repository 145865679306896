export const sortByFrequency = (arr) => {
    const frequency = {};

    arr.forEach((value) => { frequency[value] = 0; });

    const uniques = arr.filter((value) => {
        return ++frequency[value] === 1;
    });

    return uniques.sort((a, b) => {
        return frequency[b] - frequency[a];
    });
}, flattenTagArray = (arr) => {
    const x = arr.map(p => {
        return {
            tags: p.tags
        }
    });

    const y = x.map(q => q.tags);

    return y.reduce((acc, cur) => acc.concat(cur), []);
}, listAllProperties = (obj) => {
    let result = [];

    for (obj; obj !== null;
        obj = Object.getPrototypeOf(obj)) {
        result = result.concat(
            Object.getOwnPropertyNames(obj)
        );
    }

    return result;
}, countArrayValueInstances = (arr) => {
    return arr.reduce(function (allInst, inst) {
        if (inst in allInst) {
            allInst[inst]++;
        }
        else {
            allInst[inst] = 1;
        }
        return allInst;
    }, {});
}

export const getNested = (p, o) => p.reduce((xs, x) => (xs && xs[x]) ? xs[x] : null, o),
    filterById = (arr, id) => arr.filter(x => x._id === id),
    filterByTag = (arr, tag) => arr.filter((x) => {
        return x.tags.some((y) => {
            return tag.includes(y);
        });
    })







