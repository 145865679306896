export const MobileNavDivBeforeDarkT = {
    height: '90px',
    width: '100%',
    backgroundColor: 'rgba(10, 10, 10, 0)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, MobileNavDivAfterDarkT = {
    height: '90px',
    width: '100%',
    backgroundColor: 'rgba(20, 20, 20, 0.92)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, MobileNavDivBeforeDark = {
    height: '76px',
    width: '100%',
    backgroundColor: 'rgba(10, 10, 10, 0)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, MobileNavDivBeforeDarkCS = {
    height: '76px',
    width: '100%',
    backgroundColor: 'rgba(10, 10, 10, 0)',
    position: 'fixed',
    top: 48,
    zIndex: 10,
}, MobileNavDivAfterDark = {
    height: '76px',
    width: '100%',
    backgroundColor: 'rgba(20, 20, 20, 0.92)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, MobileNavDivBeforeWhite = {
    height: '73.5px',
    width: '100%',
    backgroundColor: 'rgba(244, 250, 255, 0.98)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, NavDivBeforeWhiteTab = {
    height: '82px',
    width: '100%',
    backgroundColor: 'rgba(244, 250, 255, 0.98)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}, NavDivBeforeWhiteLap ={
    height: '80px',
    width: '100%',
    backgroundColor: 'rgba(244, 250, 255, 0.98)',
    position: 'fixed',
    top: 0,
    zIndex: 10,
}