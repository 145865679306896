import React, { Component } from 'react'
import axios from 'axios'

const articleAxios = axios.create();
const AppContext = React.createContext();

export class AppContextProvider extends Component {
    constructor() {
        super()
        this.state = {
            articles: []
        }
    }

    getAllArticles = async () => {
        try {
            return articleAxios.get('/api/articles/')
            .then(response => {
                this.setState({ articles: response.data });
                return response;
            })
        } catch (error) {
            console.error(error);
        } 
    }


    componentDidMount() {
        this.getAllArticles();
    }

    componentWillUnmount() {
        this.getAllArticles();
    }

    render() {
        return (
            <AppContext.Provider
                value={{
                    getAllArticles: this.getAllArticles,
                    ...this.state
                }}
            >

                {this.props.children}

            </AppContext.Provider>
        )
    }
}

export const withContext = Component => {
    return props => {
        return (
            <AppContext.Consumer>
                {
                    globalState => {
                        return (
                            <Component
                                {...globalState}
                                {...props}
                            />
                        )
                    }
                }
            </AppContext.Consumer>
        )
    }
}
