import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import SocialIcon from './SocialIcon'
import media from '../../theme/DeviceWidth'

import LinkedInB from '../../assets/icons/linkedin_black.svg'
import LinkedInG from '../../assets/icons/linkedin_green.svg'
import GithubB from '../../assets/icons/github_black.svg'
import GithubG from '../../assets/icons/github_green.svg'
import TwitterB from '../../assets/icons/twitter_black.svg'
import TwitterG from '../../assets/icons/twitter_green.svg'
import BeehanceB from '../../assets/icons/behance_black.svg'
import BeehanceG from '../../assets/icons/behance_green.svg'

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.tablet`
        display: flex;
    `}

    ${media.laptop`
        padding: ${props => props.author ? '50px 60px 0px 100px' : '0px 80px 0px 60px'}; 
    `}

    ${media.laptopM`
        padding: ${props => props.author ? '100px 100px 0px 120px' : '0px 80px 0px 60px'}; 
    `}

    ${media.laptopL`
        padding: ${props => props.author ? '140px 100px 0px 120px' : '0px 120px 0px 100px'}; 
    `}

    ${media.laptopLL`
        padding: ${props => props.author ? '200px 100px 0px 120px' : '0px 120px 0px 100px'}; 
        margin-bottom: ${props => props.author ? '-40px' : '10px'};
    `}
`

const MemberImg = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: ${props => props.author ? 'relative' : 'static'};
    width: 90%;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.19);
    margin-left: 20px;

    ${media.tablet`
        width: ${props => props.author ? '94.5%' : '90%'};
    `}

    ${media.laptop`
        width: 90%;
        bottom: ${props => props.author ? '20px' : '0px'}; 
        border-top-left-radius: ${props => props.author ? '4px' : '0px'};
        border-top-right-radius: ${props => props.author ? '4px' : '0px'};
        border-bottom-left-radius: ${props => props.author ? '4px' : '0px'};  
        border-bottom-right-radius: ${props => props.author ? '4px' : '0px'};
    `}

    ${media.laptopM`
        bottom: ${props => props.author ? '40px' : '0px'}; 
    `}

    ${media.laptopL`
        bottom: ${props => props.author ? '100px' : '0px'}; 
    `}

    ${media.laptopLL`
        bottom: ${props => props.author ? '140px' : '0px'}; 
    `}
`

const Name = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight: 200;
    left: 20px;
    padding-top: 4px;
    position: relative;
    font-size: 1.1em;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.15em;
    `}

    ${media.phoneM`
        font-size: 1.2em;
    `}

    ${media.phoneL`
        font-size: 1.3em;
    `}

    ${media.tablet`
        font-size: 1.68em;
        letter-spacing: .05em;
    `}
`

const HoverSpan = styled.span`
    transition: color 0.6s ease;
    -o-transition: color .5s ease;
    cursor: pointer;
    
    :hover {
        color: ${props => props.theme.colors.greenMain};
    }
`

const Title = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 400;
    left: 20px;
    padding: 16px 0px 4px 0px;
    position: relative;
    font-size: 1.6em;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.7em;
    `}
    
    ${media.phoneM`
        font-size: 1.8em;
    `}
    
    ${media.phoneL`
        font-size: 1.9em;  
    `}
    
    ${media.tablet`
        font-size: 2em; 
    `}
`

const Bio = styled.div`
    line-height: 1.25;
    position: relative;
    margin-top: -4px;
    font-size: 1.1em;
    padding: 0px 18px 2px 18px;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    text-align: justify;

    ${media.phoneS`
        font-size: 1.15em;
        padding: 0px 19px 2px 19px;
    `}
    
    ${media.phoneM`
        font-size: 1.2em;
        padding: 0px 20px 2px 20px;
    `}
        
    ${media.phoneL`
        font-size: 1.3em;
        padding: 0px 20px 10px 20px;  
    `}
    
    ${media.tablet`
        font-size: 1.5em;
    `}
`

const Socials = styled.div`
    position: relative;
    left: 20px;
    padding-bottom: 22px;
`

const TabFix = styled.div`
    position: relative;
    bottom: 12px;
`

function TeamMemberPreview({ author, src, alt, id, firstName, lastName, title, bio, linkedInUri, githubUri, twitterUri, behanceUri }) {
    return (
        <RelativeContainer author={author}>
            <Fade duration={2000}>
                <MemberImg
                    author={author}
                    src={src}
                    alt={alt} />
            </Fade>
            <div>
                <Fade duration={2000}>
                    <Link
                        to={'/about/team/' + id}
                        id={id}
                        style={{ textDecoration: 'none' }} >
                        <Name><HoverSpan>{firstName + ' ' + lastName}</HoverSpan></Name>
                    </Link>
                </Fade>
                <TabFix>
                    <Fade duration={2000}>
                        <Title>{title}</Title>
                        <Bio>{bio}</Bio>
                    </Fade>
                    <Fade duration={2000}>
                        <Socials>
                            <SocialIcon
                                to={linkedInUri}
                                src={LinkedInB}
                                srcHover={LinkedInG}
                                alt='linkedInSocialIcon'
                                height='24px'
                                width='24px'
                                padding='0px 16px 0px 0px'
                                breakpoints={{
                                    410: {
                                        to: `${linkedInUri}`,
                                        height: '28px',
                                        width: '28px',
                                        padding: '0px 18px 0px 0px'
                                    },
                                    620: {
                                        to: `${linkedInUri}`,
                                        height: '32px',
                                        width: '32px',
                                        padding: '0px 20px 0px 0px'
                                    }
                                }}>
                            </SocialIcon>
                            <SocialIcon
                                to={githubUri}
                                src={GithubB}
                                srcHover={GithubG}
                                alt='githubSocialIcon'
                                height='24px'
                                width='24px'
                                padding='0px 16px 0px 0px'
                                breakpoints={{
                                    410: {
                                        to: `${githubUri}`,
                                        height: '28px',
                                        width: '28px',
                                        padding: '0px 18px 0px 0px'
                                    },
                                    620: {
                                        to: `${githubUri}`,
                                        height: '32px',
                                        width: '32px',
                                        padding: '0px 20px 0px 0px'
                                    }
                                }}>
                            </SocialIcon>
                            <SocialIcon
                                to={twitterUri}
                                src={TwitterB}
                                srcHover={TwitterG}
                                alt='twitterSocialicon'
                                height='27px'
                                width='27px'
                                top='2px'
                                padding='0px 16px 0px 0px'
                                breakpoints={{
                                    410: {
                                        to: `${twitterUri}`,
                                        height: '28px',
                                        width: '28px',
                                        padding: '0px 18px 0px 0px'
                                    },
                                    620: {
                                        to: `${twitterUri}`,
                                        height: '32px',
                                        width: '32px',
                                        padding: '0px 20px 0px 0px'
                                    }
                                }}>
                            </SocialIcon>
                            <SocialIcon
                                to={behanceUri}
                                src={BeehanceB}
                                srcHover={BeehanceG}
                                alt='behanceSocialIcon'
                                height='32px'
                                width='32px'
                                top='5px'
                                padding='0px 16px 0px 0px'
                                breakpoints={{
                                    410: {
                                        to: `${behanceUri}`,
                                        height: '36px',
                                        width: '36px',
                                        padding: '0px 18px 0px 0px'
                                    },
                                    620: {
                                        to: `${behanceUri}`,
                                        height: '40px',
                                        width: '40px',
                                        padding: '0px 20px 0px 0px'
                                    }
                                }}>
                            </SocialIcon>
                        </Socials>
                    </Fade>
                </TabFix>
            </div>
        </RelativeContainer>
    )
}

export default TeamMemberPreview;