import React from 'react'
import { stack as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'

import { bpsNM } from '../Constants/breakpoints'
import SocialIcon from '../SocialIcon'
import { SocialAltStrings, SocialHrefs } from '../Constants/paragraphStrings'

import FacebookIconWhite from '../../../assets/icons/facebook_white.svg'
import FacebookIconGreen from '../../../assets/icons/facebook_green.svg'
import InstagramIconWhite from '../../../assets/icons/instagram_white.svg'
import InstagramIconGreen from '../../../assets/icons/instagram_green.svg'

const VertSpacer = styled.div`
  height: 40px;
`

const HorizSpacer = styled.div`
  padding: 0px 32px 0px 0px;
  display: inline !important;
`

const RelativeContainer = styled.div`
  position: relative;
`

const SocialDiv = styled.div`
  position: relative;
`

function NavMenu(props) {
  return (
    <Menu
      isOpen={false}
      burgerBarClassName={props.burgerBarClassName}
      right>
      <Link to='/' id='home' className='menu-item'>Home</Link>
      <Link to='/blog/PostsList' id='blog' className='menu-item'>Blog</Link>
      <Link to='/about/agency' id='about' className='menu-item'>About</Link>
      <Link to='/services' id='services' className='menu-item'>Services</Link>
      <Link to='/caseStudies' id='caseStudies' className='menu-item'>Case Studies</Link>

      <VertSpacer></VertSpacer>

      <RelativeContainer>
        <SocialDiv>
          <SocialIcon
            to={SocialHrefs.FbHref}
            src={FacebookIconWhite}
            srcHover={FacebookIconGreen}
            alt={SocialAltStrings.FbAlt}
            height='48px'
            width='48px'
            breakpoints={bpsNM}>
          </SocialIcon>
          <HorizSpacer></HorizSpacer>
          <SocialIcon
            to={SocialHrefs.InstaHref}
            src={InstagramIconWhite}
            srcHover={InstagramIconGreen}
            alt={SocialAltStrings.InstaAlt}
            height='48px'
            width='48px'
            breakpoints={bpsNM}>
          </SocialIcon>
        </SocialDiv>
      </RelativeContainer>
    </Menu>
  )
}

export default NavMenu;