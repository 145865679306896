import React from 'react'
import styled from 'styled-components/macro'
import Helmet from 'react-helmet'
import Fade from 'react-reveal'

import { Meta } from '../../../Shared/Constants/meta'
import { memberDetails } from '../memberDetails'
import { psAgency } from '../../../Shared/Constants/paragraphStrings'
import { bpsAC, bpsAL } from '../../../Shared/Constants/breakpoints'

import CoreFeature from '../../../Shared/CoreFeature'
import InlineLinkSpan from '../../../Shared/InlineLinkSpan'
import LinkSpan from '../../../Shared/LinkSpan'
import TeamMemberPreview from '../../../Shared/TeamMemberPreview'
import media from '../../../../theme/DeviceWidth'

import CreativeDirectorImgPJM from '../../../../assets/images/pjm_author1.jpg'

const RelativeContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const CoreDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`

const ContactHeadingDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    top: 102px;
    text-align: center;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1.3em;
    letter-spacing: .3em;

    ${media.phoneS`
        font-size: 1.4em;  
    `} 

    ${media.phoneM`
        font-size: 1.6em;  
    `}
          
    ${media.phoneL`
        font-size: 1.8em;     
    `}
          
    ${media.tablet`
        font-size: 2.1em;    
    `}
      
    ${media.laptop`
        font-size: 2.2em;   
    `}
`

const SignumDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    font-size: 1.1em;
    margin: 130px 19px 10px 19px;
    text-align: justify;
      
    ${media.phoneM`
        font-size: 1.2em;
        margin: 128px 20px 10px 20px;
    `}

    ${media.tablet`
        margin: 130px 20px 10px 20px;
    `}

    ${media.laptop`
        font-size: 1.4em;
        margin: 140px 0px 20px 0px;
        padding: 0% 9%;
    `}    

    ${media.laptopL`
        font-size: 1.5em;
        padding: 0% 10%;
    `} 
`

//fix this hardcoding of team member -> move data to mongo 

function Agency() {
    return (
        <RelativeContainer>
            <Helmet titleTemplate='About | %s'>
                <title>{Meta.title}</title>
                <meta name='description' content={Meta.description}></meta>
                <meta name='keywords' content={Meta.keywords}></meta>
            </Helmet>
            <Fade duration={2000}>
                <CoreDiv>
                    <CoreFeature color='rgba(255, 255, 255, 1.0)' fontWeight='700' fontSize='4.1em'
                        letterSpacing='.2em' lineHeight='2.4em' top='43px' left='50%'
                        transform='translateX(-48%)'
                        letter1='S'
                        letter2='I'
                        letter3='G'
                        letter4='N'
                        letter5='U'
                        letter6='M'
                        breakpoints={bpsAC} />
                    <ContactHeadingDiv>MEET THE TEAM</ContactHeadingDiv>
                </CoreDiv>
                <SignumDiv>
                    <p>{psAgency.agencyP1} <span>{psAgency.agencyS2}</span><span> creative </span><InlineLinkSpan to='/services' text='digital solutions'></InlineLinkSpan><span>.</span></p>
                    <p>{psAgency.agencyP3}</p>
                    <p>{psAgency.agencyP4}</p>
                    <Fade duration={2000}>
                        <LinkSpan to='/services'
                            spanText='LEARN MORE ->'
                            fontSize='1.3em'
                            left='150px'
                            bottom='10px'
                            letterSpacing='0.5px'
                            breakpoints={bpsAL} />
                    </Fade>
                </SignumDiv>
                <TeamMemberPreview 
                    alt='Patrick Morgan Creative Director'
                    firstName={memberDetails.cdFirstName}
                    lastName={memberDetails.cdLastName}
                    bio={memberDetails.cdBlurb}
                    title={memberDetails.cdTitle}
                    id={memberDetails.cdId}
                    behanceUri={memberDetails.cdBehance}
                    githubUri={memberDetails.cdGithub}
                    linkedInUri={memberDetails.cdLinkedIn}
                    twitterUri={memberDetails.cdTwitter}
                    src={CreativeDirectorImgPJM}
                    author={false} />
            </Fade>
        </RelativeContainer>
    )
}

export default Agency;