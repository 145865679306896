import React from 'react'
import styled from 'styled-components/macro'
import Helmet from 'react-helmet'
import Fade from 'react-reveal'

import { MetaTeamPJM } from '../../../Shared/Constants/meta'

import media from '../../../../theme/DeviceWidth'
import MemberSocials from '../MemberSocials'

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.tablet`
        margin: 108px 0px 0px 0px;
    `}

    ${media.laptop`
        margin: 100px 0px 20px 0px;
        padding: 0% 9%;
    `}

    ${media.laptopM`
        margin: 100px 0px 80px 0px;
        padding: 0% 10%;
    `}

    ${media.laptopL`
        margin: 100px 0px 160px 0px;
    `}

    ${media.laptopLM`
        margin: 100px 0px 240px 0px;
    `}

    ${media.laptopLL`
        margin: 100px 0px 300px 0px;
    `}
`

const MobileImg = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;   
    display: block;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    width: 90%;
    margin: 100px 0px 4px 20px;
          
    ${media.tablet`
        display: none;
    `}
`

const MobileTitle = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    display: block;
    position: relative;
    left: 20px;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 400;
    padding: 10px 0px 4px 0px;
    font-size: 1.6em;

    ${media.phoneS`
        font-size: 1.7em;
        padding: 10px 0px 4px 0px;
    `}
      
    ${media.phoneM`
        font-size: 1.8em;
        padding: 16px 0px 4px 0px;
    `}
          
    ${media.phoneL`
        font-size: 1.9em;  
    `}

    ${media.tablet`
        display: none;
    `}
`

const MobileName = styled.div`
    transition:all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    left: 20px;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .04em;
    font-weight: 200;
    font-size: 1.1em;
    padding-top: 4px;

    ${media.phoneS`
        font-size: 1.15em;
    `}
      
    ${media.phoneM`
        font-size: 1.2em;
    `}
          
    ${media.phoneL`
        font-size: 1.3em;     
    `}
          
    ${media.tablet`
        left: 0px;
        font-size: 2.2em;
        letter-spacing: .06em;
        text-align: center;
        padding: 0px 0px 20px 0px;
    `}
`

const TabletImg = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    width: 90%;
    margin: 100px 0px 0px 20px;

    ${media.tablet`
        margin-top: 0px;
    `}
`

const TabletTitle = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    display: none;
          
    ${media.tablet`
        display: block
        position: relative;
        left: 20px;
        font-family: 'BebasNeue', bebas-neue, sans-serif;
        text-transform: uppercase;
        text-align: left;
        font-size: 1.4em;
        letter-spacing: .06em;
        font-weight: 500;
        padding: 0px;
    `}
`

const MobileBio = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    line-height: 1.25;
    text-align: justify;
    font-size: 1.1em;
    padding: 0px 18px 2px 18px;
    margin-top: -4px;
    display: block;

    ${media.phoneS`
        font-size: 1.15em;
        padding: 0px 19px 2px 19px;
    `}
      
    ${media.phoneM`
        font-size: 1.2em;
        padding: 0px 20px 2px 20px;
    `}
          
    ${media.phoneL`
        font-size: 1.3em;
        padding: 0px 20px 10px 20px;  
    `}

    ${media.tablet`
        display: none;
    `}
`

const TabletBioDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;   
    display: none;
          
    ${media.tablet`
        position: relative;
        display: block;  
        line-height: 1.25;
        white-space: pre-wrap;
        text-align: justify;
        font-size: 1.5em;
        margin-top: -4px;
        padding: 0px 20px 23px 20px;
    `}
`

const FloatDiv = styled.div`
    position: relative;
    top: 20px;
    float: right;
    width: 40%;

    ${media.laptop`
        top: 10px;
        width: 30%;
    `}

    ${media.laptopLL`
        top: 10px;
        width: 26%;
    `}
`

const HideOnTablet = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;   
    display: block;
          
    ${media.tablet`
        display: none;
    `}
`

/* decided to hardcode PJM into this along with the meta info but still abstracting the data so if need be when adding a new member just set up backend to 
accomadate "team members" and then create a context that can fetch and pass that data over to here similarly to a singular post and a post preview relationship within this application */

function TeamMemberFull({ src, alt, title, firstName, lastName, bio, linkedIn, github, twitter, behance }) {
    return (
        <RelativeContainer>
            <Helmet titleTemplate='%s | Team'>
                <title>{MetaTeamPJM.title}</title>
                <meta name='description' content={MetaTeamPJM.description}></meta>
                <meta name='keywords' content={MetaTeamPJM.keywords}></meta>
            </Helmet>
            <Fade duration={2000}>
                <MobileImg src={src} alt={alt} />
                <MobileName>{`${firstName} ${lastName}`}</MobileName>
                <MobileTitle>{title}</MobileTitle>
            </Fade>
            <Fade duration={2000}>
                <MobileBio> {bio} </MobileBio>
                <TabletBioDiv>
                    <FloatDiv>
                        <TabletImg src={src} alt={alt} />
                        <TabletTitle>{title}</TabletTitle>
                        <MemberSocials linkedInUri={linkedIn}
                            githubUri={github}
                            twitterUri={twitter}
                            behanceUri={behance} />
                    </FloatDiv>
                    {bio}
                </TabletBioDiv>
            </Fade>
            <HideOnTablet>
                <Fade duration={2000}>
                    <MemberSocials linkedInUri={linkedIn}
                        githubUri={github}
                        twitterUri={twitter}
                        behanceUri={behance} />
                </Fade>
            </HideOnTablet>
        </RelativeContainer>
    )
}

export default TeamMemberFull;