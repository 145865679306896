import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

const SolutionsSpan = styled.span`
    color: ${props => props.color};
    text-decoration-color: ${props => props.textDecorationColor} !important;
`

function InlineLinkSpan(props) {
    return (
        <Link to={props.to} id={props.to}
            style={{ textDecorationColor: props.color, display: 'inline' }} >
            <SolutionsSpan color={props.color}>{props.text}</SolutionsSpan>
        </Link>
    )
}

export default InlineLinkSpan;
