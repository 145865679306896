import React from 'react'
import styled from 'styled-components/macro'
import Particles from 'react-particles-js'

import { Landing } from '../../Shared/Constants/particleOptionParameters'

import media from '../../../theme/DeviceWidth'

const ParticleDiv = styled.div`
    background: ${props => props.theme.colors.fontDark};
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -99;
`

const LandingParentCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const LandingWords = styled.div`
  position: fixed;
  text-align: center;
  font-family: 'BebasNeue', bebas-neue, sans-serif;
  text-transform: uppercase ;
  color: #f9fbff;
  margin-left: 4px;
  z-index: 1;
  transition: all ease 0.5s;
  -o-transition: all .5s ease;

  ${media.phoneS`
    top: 27vh;
    font-size: 2.3em;
    letter-spacing: 1.8px;
  `}
      
  ${media.phoneM`
    font-size: 2.7em;
  `}
          
  ${media.phoneL`
    top: 26vh;
    font-size: 2.8em;
    letter-spacing: 2px;   
  `}
          
  ${media.tablet`
    top: 23vh;
    font-size: 3em;
    letter-spacing: 3px;
    margin-left: 8px;  
  `}
      
  ${media.laptop`
    font-size: 3.1em;
  `}   
`

const ColorSpanGreen = styled.span`
  color: ${props => props.theme.colors.greenMain};
`

function FullScreenParticles() {
  return (
    <ParticleDiv>
      <Particles width='100%' height='100vh' params={Landing} />
      <LandingParentCenter>
        <LandingWords>
          Envision a world<br /> where <ColorSpanGreen>people</ColorSpanGreen>  <br /> have meaningful <br /> experiences with <br />  the <ColorSpanGreen>brands</ColorSpanGreen> and <br />  organizations <br /> they <ColorSpanGreen>trust</ColorSpanGreen>.
        </LandingWords>
      </LandingParentCenter>
    </ParticleDiv>
  )
}

export default FullScreenParticles;

