import React from 'react'
import { Helmet } from 'react-helmet'

import { MobileNavDivBeforeDark, MobileNavDivAfterDark } from '../../Shared/Navigation/navStyleConstants'
import { bpsHN } from '../../Shared/Constants/breakpoints'
import { Meta } from '../../Shared/Constants/meta'

import Navigation from '../../Shared/Navigation/index'
import Particles from './FullScreenParticles'
import Landing from './Landing'
import styleConstants from '../../../theme/styleConstants'
import Footer from '../Footer/index'

import jpWhite from '../../../assets/icons/jp_mobile_icon_white.svg'
import jpGreen from '../../../assets/icons/jp_mobile_icon_green.svg'

function Home() {
    return (
        <div>
            <Helmet>
                <title>{Meta.title}</title>
                <meta name='description' content={Meta.description}></meta>
                <meta name='keywords' content={Meta.keywords}></meta>
            </Helmet>
            <Navigation height='44px' width='44px' top='17px' left='22px'
                color={styleConstants.colors.backgroundLight}
                mobileNavAfter={MobileNavDivAfterDark}
                mobileNavBefore={MobileNavDivBeforeDark}
                source={jpWhite}
                mouseIn={jpGreen}
                mouseOut={jpWhite}
                offsett={570}
                breakpoints={bpsHN} />
            <Particles />
            <Landing />
            <Footer dark='true'/>
        </div>
    )
}

export default Home;