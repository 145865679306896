import React, { Component } from 'react'
import styled from 'styled-components/macro'
import { withBps } from 'react-bps'

import media from '../../../theme/DeviceWidth'

const animateLine = (WrappedComponent) => {
    class Wrapper extends Component {
        constructor(props) {
            super(props);

            const { xSelector, ySelector, data } = props;

            let mappedData = data.map((d) => [xSelector(d), ySelector(d)]).reduce((arr, curr) => arr.concat(curr), []);
            let max = data.map((d) => ySelector(d)).sort((a, b) => a - b).reverse()[0];
            let liveData = mappedData.map((x, i) => i % 2 ? max : x);

            this.mappedData = mappedData;
            this.max = max;
            this.state = {
                data: liveData,
                count: 0
            };
        }

        componentDidMount() {
            const animator = () => {
                if (this.state.count >= this.max) {
                    cancelAnimationFrame(this.rafId);
                    return;
                }

                const newData = this.state.data.map((data, index) => {
                    if (index % 2) {
                        if (data > this.mappedData[index]) {
                            return data - 1;
                        }
                    }
                    return data;
                });

                this.setState({ data: newData, count: this.state.count + 1 });
                this.rafId = requestAnimationFrame(animator);
            }

            this.rafId = requestAnimationFrame(animator);
        }

        componentWillUnmount() {
            cancelAnimationFrame(this.rafId);
        }

        render() {
            return <WrappedComponent data={this.state.data} />;
        }
    }

    Wrapper.displayName = `AnimationWrapper(${WrappedComponent.displayName | WrappedComponent.name | 'Component'})`;

    return Wrapper;
};

const Line = ({ data }) => (
    <polyline
        fill="none"
        stroke="lightcoral"
        strokeWidth="2"
        points={data}
    />
);

const AnimatedLine = animateLine(Line);

const SvgThing = ({ data, vb }) => (
    <svg viewBox={vb}>
        <AnimatedLine
            data={data}
            xSelector={(d) => d.x}
            ySelector={(d) => d.y} />
    </svg>
);

let randomChartData = [];

for (let i = 0; i < 46; i++) {
    randomChartData.push({ y: Math.floor(120 - Math.random() * (120 - 0)), x: i * 20 });
}

const ChartDiv = styled.div`
    height: 28vh;
    width: 100%;
    transition: all .5s ease;
    -o-transition: all .5s ease;
    
    ${media.tablet`
        height: 26vh;
        padding-top: 20px;     
    `}
`

class AnimatedChart extends Component {
    constructor() {
        super()
        this.state = {
            scrolledPast: false
        }
        this.onScroll = this.onScroll.bind(this);
    }

    onScroll = () => {
        if ((window.pageYOffset || document.documentElement.scrollTop) > 300) {
            this.handleScrollBy(true)
        }
    }

    handleScrollBy = (isPast) => {
        this.setState({ scrolledPast: isPast })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    render() {
        return (
            <ChartDiv>
                <div>
                    {this.state.scrolledPast ? <SvgThing data={randomChartData} vb={this.props.viewbox} /> : <div></div>}
                </div>
            </ChartDiv>
        )
    }
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(AnimatedChart);