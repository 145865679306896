import { MobileNavDivBeforeDarkT, MobileNavDivAfterDarkT, NavDivBeforeWhiteTab, NavDivBeforeWhiteLap } from '../Navigation/navStyleConstants'
import { ComingSoonPL, ComingSoonT } from './particleOptionParameters'

export const bpsLCFDSC = {
    620: {
        left: '20px',
        fontWeight: '600',
        fontSize: '4em',
        letterSpacing: '.2em',
        lineHeight: '3.2em',
        top: '-56px'
    },
    1000: {
        left: '136px',
        fontSize: '4.1em',
        letterSpacing: '.21em',
        top: '-48px'
    },
    1200: {
        left: '180px',
        fontSize: '4.2em',
        letterSpacing: '.22em',
        top: '-36px'
    }
}, bpsLCFDSCreative = {
    620: {
        left: '10px',
        fontWeight: '600',
        fontSize: '4em',
        letterSpacing: '.2em',
        top: '-48px'
    },
    1000: {
        left: '130px',
        fontSize: '4.1em',
        letterSpacing: '.21em',
        top: '-52px'
    },
    1200: {
        left: '158px',
        fontSize: '4.2em',
        letterSpacing: '.22em',
        top: '-54px'
    }
}, bpsLCFBlog = {
    620: {
        left: '20px',
        fontWeight: '600',
        fontSize: '4em',
        letterSpacing: '.2em',
        lineHeight: '3.2em',
        top: '-48px'
    },
    1000: {
        fontSize: '4.1em',
        letterSpacing: '.21em',
        lineHeight: '3.2em',
        top: '-6px'
    },
    1200: {
        fontSize: '4.2em',
        letterSpacing: '.22em',
        lineHeight: '3.2em',
        top: '-10px'
    }
}, bpsLVB = {
    620: {
        viewbox: '0 0 450 300',
    }
}, bpsLSBP = {
    1200: {
        bottom: '-20px'
    }
}
    , bpsSL = {
        370: {
            left: '224px',
            fontSize: '1.4em',
            bottom: '20px'
        },
        410: {
            left: '256px',
            fontSize: '1.5em',
            bottom: '20px'
        },
        620: {
            left: '560px',
            bottom: '20px',
            fontSize: '2em',
            letterSpacing: '1px'
        },
        820: {
            left: '700px',
            bottom: '10px',
            fontSize: '2em',
            letterSpacing: '1px'
        },
        1000: {
            left: '540px',
            bottom: '20px',
            fontSize: '2em',
            letterSpacing: '1px'
        },
        1150: {
            left: '700px',
            bottom: '10px',
            fontSize: '2em',
            letterSpacing: '1px'
        },
        1200: {
            left: '840px',
            bottom: '20px',
            fontSize: '2em',
            letterSpacing: '1px'
        }
    }, bpsNM = {
        370: {
            height: '58px',
            width: '58px'
        },
        760: {
            height: '68px',
            width: '68px'
        }
    }, bpsHN = {
        640: {
            height: '50px',
            width: '50px',
            top: '18px',
            left: '32px',
            mobileNavBefore: MobileNavDivBeforeDarkT,
            mobileNavAfter: MobileNavDivAfterDarkT
        }
    }, bpsSN = {
        760: {
            height: '48px',
            width: '48px',
            top: '20px',
            left: '32px',
            mobileNavBefore: NavDivBeforeWhiteTab
        },
        1000: {
            height: '48px',
            width: '48px',
            top: '16px',
            left: '30px',
            mobileNavBefore: NavDivBeforeWhiteLap
        },
        1200: {
            height: '48px',
            width: '48px',
            top: '16px',
            left: '40px',
            mobileNavBefore: NavDivBeforeWhiteLap
        }
    }, bpsSDStrategy = {
        370: {
            leftSpan: '170px',
            bottomSpan: '330px',
            fontSize: '1.4em',
            topWhite: '-216px',
            topDark: '-216px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        410: {
            leftSpan: '192px',
            bottomSpan: '340px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        1000: {
            leftSpan: '220px',
            bottomSpan: '330px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        }
    }, bpsSDBranding = {
        370: {
            leftSpan: '170px',
            bottomSpan: '340px',
            fontSize: '1.4em',
            topWhite: '-216px',
            topDark: '-216px',
            leftWhite: '16px',
            leftDark: '-38px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        410: {
            leftSpan: '192px',
            bottomSpan: '354px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '16px',
            leftDark: '-38px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        1000: {
            leftSpan: '220px',
            bottomSpan: '350px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '16px',
            leftDark: '-38px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        }
    }, bpsSDWeb = {
        370: {
            leftSpan: '170px',
            bottomSpan: '330px',
            fontSize: '1.4em',
            topWhite: '-216px',
            topDark: '-216px',
            leftWhite: '16px',
            leftDark: '-38px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        410: {
            leftSpan: '192px',
            bottomSpan: '340px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        1000: {
            leftSpan: '220px',
            bottomSpan: '330px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        }
    }, bpsSDSoftware = {
        370: {
            leftSpan: '170px',
            bottomSpan: '290px',
            fontSize: '1.4em',
            topWhite: '-216px',
            topDark: '-216px',
            leftWhite: '16px',
            leftDark: '-38px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        410: {
            leftSpan: '192px',
            bottomSpan: '290px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        },
        1000: {
            leftSpan: '220px',
            bottomSpan: '280px',
            fontSize: '1.5em',
            topWhite: '-226px',
            topDark: '-226px',
            leftWhite: '20px',
            leftDark: '-34px',
            heightWhite: '54px',
            widthWhite: '54px',
            heightDark: '54px',
            widthDark: '54px'
        }
    }, bpsFCSI = {
        760: {
            height: '48px',
            width: '48px'
        },
        1000: {
            height: '54px',
            width: '54px'
        }
    }, bpsFCCF = {
        760: {
            fontWeight: '600',
            fontSize: '3.4em',
            letterSpacing: '.18em'
        },
        1000: {
            fontSize: '3.8em',
            letterSpacing: '.2em',
        
        }
    }, bpsP = {
        410: {
            height: '74vh',
            params: ComingSoonPL
        },
        430: {
            params: ComingSoonT
        }
    }, bpsPN = {
        640: {
            mobileNavAfter: MobileNavDivAfterDarkT
        }
    }, bpsAL = {
        340: {
            left: '196px',
            fontSize: '1.3em'
        },
        414: {
            left: '214px',
            fontSize: '1.4em',
            bottom: '2px'
        },
        760: {
            left: '540px',
            fontSize: '1.42em',
            letterSpacing: '0.6px',
            bottom: '-12px'
        },
        1000: {
            left: '700px',
            fontSize: '1.42em',
            letterSpacing: '0.6px',
            bottom: '-20px'
        },
        1200: {
            left: '800px',
            fontSize: '1.42em',
            letterSpacing: '0.6px',
            bottom: '-20px'
        },
        1350: {
            left: '900px',
            fontSize: '1.42em',
            letterSpacing: '0.6px',
            bottom: '-20px'
        }
    }, bpsAC = {
        375: {
            fontSize: '4.2em',
            letterSpacing: '0.24em'
        },
        414: {
            fontSize: '4.3em',
            letterSpacing: '0.32em',
        },
        760: {
            fontSize: '5em',
            letterSpacing: '0.3em',
            top: '33px'
        },
        1000: {
            letterSpacing: '0.22em',
            fontSize: '5.1em',
            top: '33px'
        }
    }, bpsBL = {
        375: {
            top: '37px',
            fontSize: '4.5em',
            letterSpacing: '.33em'
        },
        414: {
            top: '33px',
            fontSize: '4.8em',
            letterSpacing: '.38em'
        },
        768: {
            left: '51.5%',
            top: '29px',
            fontSize: '5.4em',
            letterSpacing: '.4em'
        },
        1000: {
            left: '50.5%',
            top: '30px',
            fontSize: '5.4em',
            letterSpacing: '.27em'
        }
    }, bpsSC = {
        760: {
            left: '40px',
            top: '0px'
        },
        1000: {
            left: '52px',
            top: '154px',
            fontSize: '3.9em'
        }
    }, bpsAF = {
        1000: {
            dark: true
        }
    }  