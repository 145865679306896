import React from 'react'
import styled from 'styled-components/macro'
import Polygon from './Polygon'
import Fade from 'react-reveal'

import { MobileNavDivBeforeDarkCS } from '../../Shared/Navigation/navStyleConstants'
import { bpsP } from '../../Shared/Constants/breakpoints'
import { ComingSoonS } from '../../Shared/Constants/particleOptionParameters'

import Navigation from '../../Shared/Navigation/index'
import media from '../../../theme/DeviceWidth'
import styleConstants from '../../../theme/styleConstants'

const CaseStudiesContainer = styled.div`
    position: fixed;
    z-index: 2;
    background: ${props => props.theme.colors.fontDark};
    width: 100%;
`

const Overlay = styled.div`
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    z-index: 2;
    background: ${props => props.theme.colors.fontDark};
    color: ${props => props.theme.colors.backgroundLight};
`

const ComingSoon = styled.div`
    position: relative;
    top: -10%;
    left: 50%;
    transform: translate(-50%, -50%);
    float: left;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 400;

    ${media.phoneS`
        font-size: 2.2em;
    `}
      
    ${media.phoneM`
        font-size: 2.3em;
    `}
          
    ${media.tablet`
        padding-top: 40px;      
    `}
`

function CaseStudies() {
    return (
        <CaseStudiesContainer>
            <Navigation display='none' color={styleConstants.colors.backgroundLight} mobileNavBefore={MobileNavDivBeforeDarkCS} />
            <Fade duration={2000}>
                <Polygon height='80vh' params={ComingSoonS} breakpoints={bpsP} />
                <Overlay>
                    <ComingSoon>Coming Soon</ComingSoon>
                </Overlay>
            </Fade>
        </CaseStudiesContainer>
    )
}

export default CaseStudies;