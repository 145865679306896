import React, { Component } from 'react'
import { withBps } from 'react-bps'
import {
    LinkedinShareButton, LinkedinIcon,
    TwitterIcon, TwitterShareButton,
    FacebookIcon, FacebookShareButton,
    PinterestIcon, PinterestShareButton,
    RedditIcon, RedditShareButton
} from 'react-share'
import Fade from 'react-reveal'
import styled from 'styled-components/macro'

import { bpsSC } from '../../../Shared/Constants/breakpoints'

import CoreFeatureVert from '../../../Shared/CoreFeatureVert'
import media from '../../../../theme/DeviceWidth'

const IconDiv = styled.div`
    position: relative;
    top: -6px;
    display: flex;
    justify-content: center;
    z-index: 10;

    ${media.laptop`
        flex-direction: column;
        top: 110px;
    `}
`

const Icon = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    opacity: 0.5;
    cursor: pointer;

    :hover{
        opacity: 1;
    }

    ${media.laptop`
        opacity: .3;
    `}
`

class SocialShare extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLaptop: false,
            scrolledPast: false
        }
    }

    setWindow = () => {
        if (window.innerWidth > 1000) {
            this.setState({
                isLaptop: true
            })
        }
    }

    //  perhaps need to throttle this for performance, import throttle from 'lodash.throttle'; but also nobody should actually be resizing this unless your a dev checking on things
    handleWindowResize = (y) => {
        this.setState({
            isLaptop: y
        })
    }

    onResize = () => {
        if (window.innerWidth > 1000) {
            this.handleWindowResize(true)
        } else {
            this.handleWindowResize(false)
        }
    }

    handleScrollBy = (x) => {
        this.setState({
            scrolledPast: x
        })
    }

    onScroll = () => {
        if ((window.pageYOffset || document.documentElement.scrollTop) > this.props.offsett) {
            this.handleScrollBy(true)
        } else {
            this.handleScrollBy(false)
        }
    }

    componentDidMount() {
        this.setWindow()
        window.addEventListener('resize', this.onResize)
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('scroll', this.onScroll)
    }

    render() {
        const { shareUrl, fbQuote, iconSize, title, twitterVia, twitterHashtagsArray, description, media } = this.props
        return (
            <div className={this.state.scrolledPast ? 'RelativeSSVisible' : 'RelativeSSHidden'}>
                <CoreFeatureVert letterColor='rgba(255, 255, 255, 1.0)' top='-20px' left='20px'
                    fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='1em'
                    letter1='S'
                    letter2='H'
                    letter3='A'
                    letter4='R'
                    letter5='E'
                    breakpoints={bpsSC} />
                <Fade duration={2500}>
                    <IconDiv>
                        <Icon>
                            <FacebookShareButton url={shareUrl} quote={fbQuote}>
                                {this.state.isLaptop
                                    ? <FacebookIcon size={iconSize} />
                                    : <FacebookIcon size={iconSize} round />
                                }
                            </FacebookShareButton>
                        </Icon>
                        <Icon>
                            <TwitterShareButton url={shareUrl} title={title} via={twitterVia} hashtags={twitterHashtagsArray}>
                                {this.state.isLaptop
                                    ? <TwitterIcon size={iconSize} />
                                    : <TwitterIcon size={iconSize} round />
                                }
                            </TwitterShareButton>
                        </Icon>
                        <Icon>
                            <LinkedinShareButton url={shareUrl} title={title} source='www.jpsignum.com' summary={description}>
                                {this.state.isLaptop
                                    ? <LinkedinIcon size={iconSize} />
                                    : <LinkedinIcon size={iconSize} round />
                                }
                            </LinkedinShareButton>
                        </Icon>
                        <Icon>
                            <PinterestShareButton url={shareUrl} media={media} description={description}>
                                {this.state.isLaptop
                                    ? <PinterestIcon size={iconSize} />
                                    : <PinterestIcon size={iconSize} round />
                                }
                            </PinterestShareButton>
                        </Icon>
                        <Icon>
                            <RedditShareButton url={shareUrl} title={title}>
                                {this.state.isLaptop
                                    ? <RedditIcon size={iconSize} />
                                    : <RedditIcon size={iconSize} round />
                                }
                            </RedditShareButton>
                        </Icon>
                    </IconDiv>
                </Fade>
            </div>
        )
    }
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(SocialShare);

