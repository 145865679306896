import React from 'react'
import styled from 'styled-components/macro'

import media from '../../../theme/DeviceWidth'

const AnimatedScrollDown = styled.span`
    width: 24px;
    height: 24px;
    margin-left: 2px;
    border-left: 2px solid #f9fbff;
    border-bottom: 2px solid #f9fbff;
    box-sizing: border-box;
    transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg); 
`

const AnimatedScrollDown1 = styled(AnimatedScrollDown)`
    width: 30px;
    height: 30px;
    margin-left: -1px;
`

const AnimatedScrollDown2 = styled(AnimatedScrollDown1)`
    width: 36px;
    height: 36px;
    margin-left: -4px;
`

const ArrowDiv = styled.div`
    display: flex;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    bottom: 4vh;
    
    ${media.tablet`
        bottom: 0vh;
    `}
`

const ArrowFlexDiv = styled.div`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    position: relative;

    ${media.phoneS`
        margin-top: -9vh;
    `}
      
    ${media.phoneM`
        margin-top: -10;
    `}  
`

function ArrowScrollComponent() {
    return (
        <ArrowFlexDiv>
            <ArrowDiv>
                <AnimatedScrollDown className='scrollDownBlink1'></AnimatedScrollDown>
            </ArrowDiv>
            <ArrowDiv>
                <AnimatedScrollDown1 className='scrollDownBlink2'></AnimatedScrollDown1>
            </ArrowDiv>
            <ArrowDiv>
                <AnimatedScrollDown2 className='scrollDownBlink3'></AnimatedScrollDown2>
            </ArrowDiv>
        </ArrowFlexDiv>
    )
}

export default ArrowScrollComponent;