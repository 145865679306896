import React, { Component } from 'react'
import { withBps } from 'react-bps'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import NavMenu from './Menu'

import JpMobileIcon from '../JpIcon'

const LaptopMenu = styled.div`
    z-index: 10;
    position: relative;
    display: flex;
    justify-content: flex-end;
`

const Name = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .058em;
    font-weight: 200;
    position: relative;
    right: 32px;
    font-size: 2em;
    top: -26px;
    padding-left: 32px;
    color: ${props => props.color};
`

const RelativeContainer = styled.div`
    position: relative;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
`

const HoverSpan = styled.span`
    transition: color 0.6s ease;
    -o-transition: all .6s ease;
    cursor: pointer;
    
    :hover {
        color: ${props => props.theme.colors.greenMain};
    }
`

class NavigationMobile extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLaptop: false,
            scrolledPast: false
        }

    }

    setWindow = () => {
        if (window.innerWidth > 1000) {
            this.setState({
                isLaptop: true
            })
        }
    }

    handleScrollBy = (x) => {
        this.setState({
            scrolledPast: x
        })
    }

    onScroll = () => {
        if ((window.pageYOffset || document.documentElement.scrollTop) > this.props.offsett) {
            this.handleScrollBy(true)
        } else {
            this.handleScrollBy(false)
        }
    }

    //  perhaps need to throttle this for performance, import throttle from 'lodash.throttle'; but also nobody should actually be resizing this unless your a dev checking on things
    handleWindowResize = (y) => {
        this.setState({
            isLaptop: y
        })
    }

    onResize = () => {
        if (window.innerWidth > 1000) {
            this.handleWindowResize(true)
        } else {
            this.handleWindowResize(false)
        }
    }

    componentDidMount() {
        this.setWindow()
        window.addEventListener('scroll', this.onScroll)
        window.addEventListener('resize', this.onResize)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
        window.removeEventListener('resize', this.onResize)
    }

    render() {
        return (
            <div style={this.state.scrolledPast ? this.props.mobileNavAfter : this.props.mobileNavBefore}>
                <JpMobileIcon
                    height={this.props.height}
                    width={this.props.width}
                    top={this.props.top}
                    left={this.props.left}
                    source={this.props.source}
                    mouseOver={this.props.mouseIn}
                    mouseOut={this.props.mouseOut}
                    display={this.props.display} />
                <RelativeContainer>
                    {this.state.isLaptop
                        ? <LaptopMenu >
                            <Link to='/blog/PostsList' style={{ textDecoration: 'none' }} >
                                <Name color={this.props.color}><HoverSpan>Blog</HoverSpan></Name></Link>
                            <Link to='/about/agency' style={{ textDecoration: 'none' }} >
                                <Name color={this.props.color}><HoverSpan>About</HoverSpan></Name></Link>
                            <Link to='/services' style={{ textDecoration: 'none' }} >
                                <Name color={this.props.color}><HoverSpan>Services</HoverSpan></Name></Link>
                            <Link to='/caseStudies' style={{ textDecoration: 'none' }} >
                                <Name color={this.props.color}><HoverSpan>Case Studies</HoverSpan></Name></Link>
                        </LaptopMenu>
                        : <NavMenu burgerBarClassName={this.props.burgerBarClassName} />
                    }
                </RelativeContainer>
            </div>
        )
    }
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(NavigationMobile);