import React from 'react'
import styled from 'styled-components/macro'

import ProjectsLink from './ProjectsLink'
import media from '../../theme/DeviceWidth'

import RecentProjectsPic from '../../assets/images/jp_designLanding3.jpg'
import TeamMeetPic from '../../assets/images/jp_designLanding4_opt.jpg'

const FlexLinkContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

    ${media.tablet`
        display: flex;
        max-width: 1000px;
    `}

    ${media.laptop`
        display: none;
    `}
`

const TeamLinkContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    display: none;

    ${media.tablet`
        display: contents;
    `}

    ${media.laptop`
        display: none;
    `}
`

function FLC() {
    return (
        <FlexLinkContainer>
            <ProjectsLink
                src={RecentProjectsPic}
                alt='Check out some recents projects we are proud of'
                span1='Check out some recent '
                linkSpanText='projects'
                span2=' we are proud of.'
                to='/caseStudies' />
            <TeamLinkContainer>
                <ProjectsLink
                    src={TeamMeetPic}
                    alt='Meet the team and learn more about our mission'
                    span1='Meet the '
                    linkSpanText='team'
                    span2=' and learn more about our mission.'
                    to='/about/agency' />
            </TeamLinkContainer>
        </FlexLinkContainer>
    )
}

export default FLC;