import React, { Component } from 'react'
import styled from 'styled-components/macro'
import media from '../../../theme/DeviceWidth'

const RelativeContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
    min-height: 200px;
    align-items: center;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
`

const PaddingDiv = styled.div`
    padding: 0px 10px 10px 10px;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
        
    ${media.laptop`
        padding: 30px 100px 90px 100px;        
    `}
        
    ${media.laptopL`
        padding: 40px 140px 90px 140px;        
    `}
`

class Quote extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeMediaIndex: 0
        }
    }

    intervalID = 0;

    componentDidMount() {
        this.intervalID = setInterval(this.changeActiveMedia, 8020);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
    }


    changeActiveMedia = () => {
        const mediaListLength = this.props.quotes.length;
        let nextMediaIndex = this.state.activeMediaIndex + 1;

        if (nextMediaIndex >= mediaListLength) {
            nextMediaIndex = 0;
        }

        this.setState({ activeMediaIndex: nextMediaIndex });
    }

    renderSlideshow() {
        const q = this.props.quotes[this.state.activeMediaIndex];

        return (
            <PaddingDiv>
                <h2>&ldquo;{q.text}&rdquo;</h2>
                <h3>&mdash;{q.author}</h3>
            </PaddingDiv>
        )
    }

    render() {
        return (
            <RelativeContainer>
                {this.renderSlideshow()}
            </RelativeContainer>
        )
    }
}

export default Quote;