import React from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Parallax } from 'react-scroll-parallax'

import { sortByFrequency, getNested, flattenTagArray } from '../../Shared/Constants/helperFunctions'
import { bpsLCFDSC, bpsLCFDSCreative, bpsLCFBlog, bpsLVB } from '../../Shared/Constants/breakpoints'
import { psLanding } from '../../Shared/Constants/paragraphStrings'
import { withContext } from '../../AppContext'

import LinkSpanW from '../../Shared/LinkSpanW'
import media from '../../../theme/DeviceWidth'
import ArrowScroll from './ArrowScroll'
import AnimatedChart from './AnimatedChart'
import CoreFeature from '../../Shared/CoreFeature'
import DotGrid from '../../Shared/DotGrid'
import ServiceList from './ServiceList'
import InlineLinkSpan from '../../Shared/InlineLinkSpan'
import ProjectsLink from '../../Shared/ProjectsLink'
import DesDevSvg1 from './DesignDevelopment'
import styleConstants from '../../../theme/styleConstants'

import RecentProjectsPic from '../../../assets/images/jp_designLanding3.jpg'
import TeamMeetPic from '../../../assets/images/jp_designLanding4_opt.jpg'
import StrategyPic1 from '../../../assets/images/jp_strategyLanding.jpg'

const OverflowFixDiv = styled.div`
    overflow-x: hidden;
    overflow-y: hidden;
`

const MobileLandingDiv1 = styled.div`
    background: ${props => props.theme.colors.fontDark};
    color: ${props => props.theme.colors.backgroundLight};
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        margin-top: 100vh;
    `}
        
    ${media.phoneM`
        margin-top: 100vh;
    `}
            
    ${media.tablet`
        margin-top: 103vh;   
    `}     
`

const MobileLandingDiv2 = styled(MobileLandingDiv1)` 
    width: 100%;
    padding-bottom: 8px;

    ${media.phoneS`
        margin-top: 0 !important;
    `}
        
    ${media.phoneM`
        margin-top: 0 !important;
    `}
            
    ${media.phoneL`
        margin-top: 0 !important;    
    `}
            
    ${media.tablet`
        margin-top: -4px !important;      
    `}
        
    ${media.laptop`
        margin-top: -4px !important;     
        padding: 0px 10px 30px 10px; 
    `}
        
    ${media.laptopL`
        margin-top: -4px !important;
        padding: 0px 20px 34px 20px;      
    `}
`

const CoreDiv = styled.div`
    position: relative;
    top: -18px;
`

const BlogCoreDiv = styled(CoreDiv)`
    top: -8px;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.tablet`
        top: -12px;  
    `}
`

const MarkConDiv = styled.div`
    line-height: 1.5;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.1em;
        padding: 20px;
    `}
        
    ${media.phoneM`
        font-size: 1.2em;
        padding: 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.26em;   
        padding: 22px; 
    `}
            
    ${media.tablet`
        font-size: 1.6em;   
        padding: 2px 32px 0px 32px;        
    `}
        
    ${media.laptop`
        padding: 10px 160px 0px 160px;     
    `}
        
    ${media.laptopL`
        padding: 20px 200px 0px 200px;        
    `}
`

const BlogRoundupDiv = styled.div`
    margin-top: -20px;
    line-height: 1.12;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    font-size: 1em;
    padding: 20px;
        
    ${media.phoneM`
        font-size: 1.1em;
        padding: 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.2em;
        padding: 22px;      
    `}
            
    ${media.tablet`
        font-size: 1.6em;
        padding: 20px 32px 30px 32px;            
    `}
`

const VerticalSpacerDiv = styled.div`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        height: 26vh;
    `}
        
    ${media.phoneM`
        height: 27vh;
    `}
            
    ${media.phoneL`  
        height: 28vh; 
    `}
            
    ${media.tablet`
        display: none; 
    `}
`

const TechHeadingP = styled.p`
    padding-top: 8px;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    position: relative;
    left: 28px;
    letter-spacing: 0.5px;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.7em;
    `}
        
    ${media.phoneM`
        font-size: 2em;
    `}
            
    ${media.phoneL`
        font-size: 2em;   
    `}
            
    ${media.tablet`
        font-size: 2.7em;    
        padding-top: 0px;
        top: -10px;      
    `}
        
    ${media.laptop`
        left: 174px; 
        top: -10px;   
    `}
        
`

const BlogHeadingP = styled(TechHeadingP)`
    top: 32px;
    left: 20px;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 2em;
    `}
        
    ${media.phoneM`
        font-size: 2.1em;
    `}
            
    ${media.phoneL`
        font-size: 2.4em;
    `}
            
    ${media.tablet`
        font-size: 2.7em; 
        top: 42px;
        left: 32px;       
    `}
`

const RelativeContainer = styled.div`
    position: relative;
`

const RelativeContainerBlogLeft = styled(RelativeContainer)`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

    ${media.laptop`
        margin: 0px 40px 0px 84px;
    `}
`

const RelativeContainerBlogRight = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.laptop`
        margin-right: 60px;
    `}

    ${media.laptopL`
        margin-right: 120px;
    `}

    ${media.laptopLM`
        margin-right: 140px;
    `}

    ${media.laptopLL`
        margin-right: 180px;
    `}
`

const RelativeContainerPad = styled(RelativeContainer)`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.tablet`
        padding-top: 80px;            
    `}

    ${media.laptop`
        padding-top: 140px;            
    `}

    ${media.laptopL`
        padding-top: 180px;            
    `}

    ${media.desktop`
        padding-top: 220px;            
    `}
`

const RelativeContainerList = styled(RelativeContainer)`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneL`
        padding-bottom: 10px;
    `}
`

const BlogLandingDiv = styled.div`
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    margin: 6px 0px -30px 0px;
   
    ${media.phoneL`
        margin-bottom: -34px;       
    `}
            
    ${media.tablet`
        margin-bottom: -40px;         
    `}
`

const GridBlogContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    top: -60px;
    width: 320px;
        
    ${media.phoneM`
        top: -54px;
        width: 375px;
    `}
            
    ${media.phoneL`
        top: -70px;
        width: 414px;
    `}
            
    ${media.tablet`
        top: -40px;
        width: 768px;
    `}

    ${media.laptop`
        width: 100%;
        display: grid;
        grid-template-columns: auto auto ;
    `}
`

const BlogSectionContainer = styled.div`
    ${media.laptop`
        width: 500px;
    `}

    :hover > a div {
        transition: all 0.5s ease;
            -o-transition: transform 0.5s ease;
        background: ${props => props.theme.colors.fontDark};

    ${media.tablet`
        transform: translateY(90px);
            -webkit-transform: translateY(90px);
            -moz-transform: translateY(90px);
            -o-transform: translateY(90px);
            -ms-transform: translateY(90px);       
    `}

    ${media.laptop`
        transform: translateY(62px);
            -webkit-transform: translateY(62px);
            -moz-transform: translateY(62px);
            -o-transform: translateY(62px);
            -ms-transform: translateY(62px);       
    `}}

    :hover > div  {
        transition: transform 0.5s ease;
            -o-transition: transform 0.5s ease;

    ${media.tablet`
        transform: translateY(-90px);
            -webkit-transform: translateY(-90px);
            -moz-transform: translateY(-90px);
            -o-transform: translateY(-90px);
            -ms-transform: translateY(-90px);       
    `}

    ${media.laptop`
        transform: translateY(-62px);
            -webkit-transform: translateY(-62px);
            -moz-transform: translateY(-62px);
            -o-transform: translateY(-62px);
            -ms-transform: translateY(-62px);       
    `}}

    :hover > a div img {
        transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
        opacity: 0.5;
    }
`

const BlogPostImgDiv = styled.div`
    transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
    width: 89.5%;
    margin-left: 18px; 

    ${media.phoneM`
        width: 90%;
    `}

    ${media.tablet`
        margin-left: 40px;     
    `}

    ${media.laptopL`
        width: 95%;
    `}

    ${media.laptopLM`
        width: 96.5%;
    `}

    ${media.laptopLL`
        width: 98%;
    `}
`

const BlogImage = styled.img`
    width: 100%;
    opacity: 1;
    cursor: pointer;
`

const BlogDateDiv = styled.div`
    transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
    color: ${props => props.theme.colors.greenMain};
    font-size: 1.1em;  
    font-weight: 400;    
    padding: 0px 0px 8px 0px;  
        
    ${media.phoneM`
        font-size: 1.2em;
        padding: 10px 0px 8px 0px;  
    `}
            
    ${media.phoneL`
        font-size: 1.3em; 
    `}
            
    ${media.tablet`
        font-size: 1.4em;
        padding-left: 40px;        
    `}

    ${media.laptop`
        font-size: 1.5em;
        padding: 0px 0px 8px 30px;  
    `}
`

const BlogTitleDiv = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .09em;
    color: ${styleConstants.colors.backgroundLight};
    transition: ease 0.5s;
    transition: all .5s ease;
    font-size: 1.1em;  

    ${media.phoneS`
        font-size: 1.3em;
    `}

    ${media.phoneM`
        font-size: 1.4em;
    `}

    ${media.phoneL`
        font-size: 1.5em;
    `}

    ${media.tablet`
        font-size: 1.6em;
        padding: 0px 40px 0px 40px;
    `}

    ${media.laptop`
        font-size: 1.4em;
        padding: 0px 10px 0px 30px;
    `}
`

const BlogTitlesContainer = styled.div`
    text-decoration: none;
    transition: all .5s ease;
    -o-transition: all .5s ease;
    padding: 0px 20px 0px 20px;

    ${media.tablet`
        padding: 14px 132px 14px 0px;
    `}

    ${media.laptop`
        padding: 0px 10px 0px 30px;
    `}
`
const ParallaxWrapper = styled.div`
    position: relative;
    z-index: -1;
`

const ParallaxStrategyImg = styled.img`
    width: 100%;
    margin-top: -32vh;
`

const FlexLinkContainer = styled.div`
    transition:all ease 0.5s;
        -o-transition: all .5s ease;
    padding-bottom: 10px;    

    ${media.phoneSM`
       padding-bottom: 25px;
    `}

    ${media.tablet`
        display: flex;
        padding-bottom: 30px;
        max-width: 1000px;
    `}

`

const TeamLinkContainer = styled.div`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    display: none;

    ${media.tablet`
        display: contents;
    `}

    ${media.laptop`
        margin-left: 40px;
    `}

`

const LinkSpacer = styled.div`
    transition: all .5s ease;
      -o-transition: all .5s ease;
    
    ${media.laptop`
        width: 200px;
    `}

    ${media.laptop`
        width: 240px;
    `}
`

function Landing(props) {
    const rawPostsArr = props.articles,
        firstPostNum = rawPostsArr.length - 1,
        flattenedAllPostsTagArr = flattenTagArray(rawPostsArr),
        sortedTagArray = sortByFrequency(flattenedAllPostsTagArr);

    const firstPostObject = rawPostsArr[firstPostNum],
        firstPostId = getNested(['_id'], firstPostObject),
        firstPostImgUrl = getNested(['image'], firstPostObject),
        firstPostImgAlt = getNested(['image_alt'], firstPostObject),
        firstPostDate = getNested(['published_on'], firstPostObject),
        formatedFirstPostDate = moment(firstPostDate).format('MMMM YYYY'),
        firstPostTitle = getNested(['fields_title'], firstPostObject),
        categoryName = getNested(['category_name'], firstPostObject)

    const isLaptop = window.innerWidth > 1000;
    return (
        <OverflowFixDiv>
            <MobileLandingDiv1>
                <ArrowScroll />
                <RelativeContainer>
                    <CoreDiv>
                        <CoreFeature letterColor='rgba(2, 2, 2, 0.95)' top='-16px' left='10px'
                            fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                            letter1='D'
                            letter2='I'
                            letter3='G'
                            letter4='I'
                            letter5='T'
                            letter6='A'
                            letter7='L'
                            breakpoints={bpsLCFDSC} />
                    </CoreDiv>
                    <Fade duration={1800} up>
                        <MarkConDiv>
                            <p> {psLanding.marketingP} </p>
                        </MarkConDiv>
                    </Fade>
                </RelativeContainer>
                <AnimatedChart viewbox='0 0 250 300' breakpoints={bpsLVB} />
                <RelativeContainerPad>
                    <CoreDiv>
                        <CoreFeature letterColor='rgba(2, 2, 2, 0.95)' top='-16px' left='10px'
                            fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                            letter1='S'
                            letter2='T'
                            letter3='R'
                            letter4='A'
                            letter5='T'
                            letter6='E'
                            letter7='G'
                            letter8='Y'
                            breakpoints={bpsLCFDSC} />
                    </CoreDiv>
                    <MarkConDiv>
                        <Fade duration={2000} up>
                            <p> {psLanding.consultationP} </p>
                            <div>
                                <p> {psLanding.agencyP} <span> creative </span><InlineLinkSpan color={styleConstants.colors.greenMain} to='/services' text='digital solutions' /><span>.</span> </p>
                            </div>
                        </Fade>
                    </MarkConDiv>
                </RelativeContainerPad>
            </MobileLandingDiv1>
            <VerticalSpacerDiv>
                <ParallaxWrapper>
                    <Parallax slowerScrollRate={true} offsetYMax={20} offsetYMin={-20} >
                        <ParallaxStrategyImg src={StrategyPic1} alt='Person holding newspaper with headline reading simply the word Ideas' />
                    </Parallax>
                </ParallaxWrapper>
            </VerticalSpacerDiv>
            <MobileLandingDiv2>
                <Fade duration={2000}>
                    <DesDevSvg1 />
                </Fade>
                <RelativeContainerList>
                    <CoreDiv>
                        <CoreFeature letterColor='rgba(2, 2, 2, 0.95)' top='-16px' left='10px'
                            fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                            letter1='C'
                            letter2='R'
                            letter3='E'
                            letter4='A'
                            letter5='T'
                            letter6='I'
                            letter7='V'
                            letter8='E'
                            breakpoints={bpsLCFDSCreative} />
                    </CoreDiv>
                    <Fade duration={2000} up>
                        <TechHeadingP>Technology And Development</TechHeadingP>
                    </Fade>
                    <ServiceList />
                </RelativeContainerList>
                <FlexLinkContainer>
                    <div>
                        <ProjectsLink src={RecentProjectsPic}
                            alt='wireframe and cellphone looking like someones half finished work'
                            span1='Check out some recent '
                            linkSpanText='projects'
                            span2=' we'
                            span3=' are proud of.'
                            to='/caseStudies' />
                    </div>
                    {isLaptop
                        ? <LinkSpacer></LinkSpacer>
                        : <div></div>
                    }
                    <TeamLinkContainer>
                        <ProjectsLink src={TeamMeetPic}
                            alt='Person holding newspaper with headline reading simply the word Ideas'
                            span1='Meet the '
                            linkSpanText='team'
                            span2=' and learn more about our mission.'
                            to='/about/agency' />
                    </TeamLinkContainer>
                </FlexLinkContainer>
                <BlogLandingDiv>
                    <DotGrid />
                    <GridBlogContainer>
                        <RelativeContainerBlogLeft>
                            <BlogCoreDiv>
                                <CoreFeature letterColor='rgba(10, 10, 10, 0.9)' top='-20px' left='10px'
                                    fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                                    letter1='B'
                                    letter2='L'
                                    letter3='O'
                                    letter4='G'
                                    breakpoints={bpsLCFBlog} />
                            </BlogCoreDiv>
                            <Fade duration={1000} up>
                                <BlogHeadingP>Breaking News</BlogHeadingP>
                                <BlogRoundupDiv>
                                    <span>Head over to the </span>
                                    <InlineLinkSpan to='/blog/PostsList' text='blog' color={styleConstants.colors.greenMain} />
                                    <span> {psLanding.blogP} </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[0]} color={styleConstants.colors.greenMain} /><span>, </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[1]} color={styleConstants.colors.greenMain} /><span>, and </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[2]} color={styleConstants.colors.greenMain} /><span>.</span>
                                </BlogRoundupDiv>
                                {isLaptop
                                    ? <Fade duration={2000}>
                                        <LinkSpanW
                                            to='/blog/PostsList'
                                            spanText='NEW PROJECT'
                                            fontSize='2.4em'
                                            left='144px'
                                            bottom='-20px'
                                            letterSpacing='1px' />
                                    </Fade>
                                    : <div></div>
                                }
                            </Fade>
                        </RelativeContainerBlogLeft>
                        <RelativeContainerBlogRight>
                            <Fade duration={1500}>
                                <BlogSectionContainer>
                                    <Link to={'/blog/' + firstPostId} id={firstPostId} style={{ textDecoration: 'none' }} >
                                        <BlogPostImgDiv>
                                            <BlogImage src={firstPostImgUrl} alt={firstPostImgAlt} />
                                        </BlogPostImgDiv>
                                    </Link>
                                    <div>
                                        <Link to={'/blog/' + firstPostId} id={firstPostId} style={{ textDecoration: 'none' }} >
                                            <BlogTitlesContainer>
                                                <BlogDateDiv>&mdash; {formatedFirstPostDate}</BlogDateDiv>
                                                <BlogTitleDiv>{firstPostTitle}<span className='labelLanding arrowed-in'> {' ' + categoryName} </span></BlogTitleDiv>
                                            </BlogTitlesContainer>
                                        </Link>
                                    </div>
                                </BlogSectionContainer>
                            </Fade>
                        </RelativeContainerBlogRight>
                    </GridBlogContainer>
                </BlogLandingDiv>
            </MobileLandingDiv2>
        </OverflowFixDiv>
    )
}

export default withContext(Landing);