import React from 'react'
import styled from 'styled-components/macro'

import { MobileNavDivBeforeWhite } from '../../../Shared/Navigation/navStyleConstants'
import { bpsSN } from '../../../Shared/Constants/breakpoints'

import Navigation from '../../../Shared/Navigation/index'
import SingularPost from './SingularPost'
import FLC from '../../../Shared/FLC'
import media from '../../../../theme/DeviceWidth'
import styleConstants from '../../../../theme/styleConstants'
import Footer from '../../Footer/index'

import jpBlack from '../../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../../assets/icons/jp_mobile_icon_green.svg'

const SingularPostContainer = styled.div`
    position: relative;
    overflow: hidden;
    background: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};

    ${media.tablet`
        margin-bottom: -40px;
    `}
`

function SingularPostView({ match: { params: id } }) {
    return (
        <SingularPostContainer>
            <Navigation height='44px' width='44px' top='17px' left='22px'
                color={styleConstants.colors.fontDark}
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName='bm-burger-bars-DARK'
                breakpoints={bpsSN} />
            <SingularPost id={id} />
            <FLC />
            <Footer />
        </SingularPostContainer>
    )
}

export default SingularPostView;