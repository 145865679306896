import React from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import { bpsSN } from '../../Shared/Constants/breakpoints'
import { MobileNavDivBeforeWhite } from '../../Shared/Navigation/navStyleConstants'

import Navigation from '../../Shared/Navigation/index'
import HeroBody from './HeroBody'
import Footer from '../Footer/index'
import styleConstants from '../../../theme/styleConstants'

import jpBlack from '../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../assets/icons/jp_mobile_icon_green.svg'

const ServicesContainer = styled.div`
    width: 100%;
    position: relative;
    background-color: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};
    overflow: hidden;
`

function Services() {
    return (
        <ServicesContainer>
            <Navigation height='44px' width='44px' top='17px' left='22px' 
                color={styleConstants.colors.fontDark} 
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName='bm-burger-bars-DARK' 
                breakpoints={bpsSN}/>
            <Fade duration={2000}>
                <HeroBody />
            </Fade>
            <Footer dark='true'/>
        </ServicesContainer>
    )
}

export default Services;