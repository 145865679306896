export const Landing = {
    particles: {
      number: {
        value: 70,
        density: {
          enable: true,
          value_area: 1900
        }
      },
      color: {
        value: "#f8fcfd"
      },
      shape: {
        type: "circle",
        stroke: {
          width: .5,
          color: "#f8fcfd"
        }
      },
      move: {
        speed: 0.9
      }
    }
}, Service = {
  particles: {
    number: {
      value: 100,
      density: {
        enable: true,
        value_area: 1000
      }
    },
    color: {
      value: "#f8fcfd"
    },
    shape: {
      type: "circle",
      stroke: {
        width: .5,
        color: "#f8fcfd"
      }
    },
    move: {
      speed: .35
    }
  }
}, ComingSoonS = {
    "fps_limit": 28,
    "particles": {
        "number": {
            "value": 120,
            "density": {
                "enable": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 26,
            "opacity": 0.6,
            "color": '#41f3b7'
        },
        "move": {
            "speed": 1
        },
        "opacity": {
            "anim": {
                "enable": true,
                "opacity_min": 0.05,
                "speed": 2,
                "sync": false
            },
            "value": 0.4
        }
    },
    "polygon": {
        "enable": true,
        "scale": 2,
        "type": "inline",
        "move": {
            "radius": 11
        },
        'url': '/jp_poygon_singlePath.svg',
        "inline": {
            "arrangement": "equidistant"
        },
        "draw": {
            "enable": true,
            "stroke": {
                "color": "rgba(255, 255, 255, .0)"
            }
        }
    },
    "retina_detect": true,
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            }
        },
        "modes": {
            "bubble": {
                "opacity": 0.2,
                "distance": 29,
                size: 4
            }
        }
    }
}, ComingSoonPL = {
    "fps_limit": 28,
    "particles": {
        "number": {
            "value": 120,
            "density": {
                "enable": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 26,
            "opacity": 0.6,
            "color": '#41f3b7'
        },
        "move": {
            "speed": 1
        },
        "opacity": {
            "anim": {
                "enable": true,
                "opacity_min": 0.05,
                "speed": 2,
                "sync": false
            },
            "value": 0.4
        }
    },
    "polygon": {
        "enable": true,
        "scale": 2.4,
        "type": "inline",
        "move": {
            "radius": 11
        },
        'url': '/jp_poygon_singlePath.svg',
        "inline": {
            "arrangement": "equidistant"
        },
        "draw": {
            "enable": true,
            "stroke": {
                "color": "rgba(255, 255, 255, .0)"
            }
        }
    },
    "retina_detect": true,
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            }
        },
        "modes": {
            "bubble": {
                "opacity": 0.2,
                "distance": 29,
                size: 4
            }
        }
    }
}, ComingSoonT = {
    "fps_limit": 28,
    "particles": {
        "number": {
            "value": 120,
            "density": {
                "enable": false
            }
        },
        "line_linked": {
            "enable": true,
            "distance": 26,
            "opacity": 0.6,
            "color": '#41f3b7'
        },
        "move": {
            "speed": 1
        },
        "opacity": {
            "anim": {
                "enable": true,
                "opacity_min": 0.05,
                "speed": 2,
                "sync": false
            },
            "value": 0.4
        }
    },
    "polygon": {
        "enable": true,
        "scale": 2,
        "type": "inline",
        "move": {
            "radius": 11
        },
        'url': '/jp_poygon_singlePath.svg',
        "inline": {
            "arrangement": "equidistant"
        },
        "draw": {
            "enable": true,
            "stroke": {
                "color": "rgba(255, 255, 255, .0)"
            }
        }
    },
    "retina_detect": true,
    "interactivity": {
        "events": {
            "onhover": {
                "enable": true,
                "mode": "bubble"
            }
        },
        "modes": {
            "bubble": {
                "opacity": 0.2,
                "distance": 29,
                size: 4
            }
        }
    }
}


