export const ServicesDescription = {
    title: "Connecting Brands With People",
    description1: "Every client has unique stories, goals, and challenges. We understand these challenges and provide unique solutions tailored to your needs. Our goal is to nurture the bond between customers and your brand by creating concepts and ideas that not only delight end users but also foster lasting relationships. Relationships that will grow and be rewarding over time, remember fresh thinking is always required but passion is our driving force. With innovative integrated solutions, creative & strategic thinking, and data driven analysis we can take your brand to the next level.",
}, StrategyDescription = {
    title: "Strategic Thinking",
    description1: 'Abraham Lincoln once said that, given 8 hours to chop down a tree, he would spend six sharpening his axe. We create strategies that are agile, flexible, and allow for real-time interactions. As strategists, we are committed to understanding the motivations that drive audience behavior. As communicators, we work to creatively influence that behavior. Through in depth research we are able to guide clients by making swift adjustments based on data driven results and insights. \n\n"There once was a serpent who only traveled in one direction. Always forward, never backward..." \n\nAs the story of the', 
    description2: 'goes this is not the end but the begining of a new cycle. Advances in analytics have armed us with mountains of data, that we use to align your hopes and dreams into a reality. Post launch we will adjust and redefine our benchmarks of sucess by constantly analyzing your goals and objectives in order to stay focused while exploring additional sucessful oppourtunities.',
}, StrategyQuoteList = [
    {id:1, text:"A vision without a strategy remains an illusion.", author:"Lee Bolman" },
    {id:2, text:"Strategy is style of thinking, a conscious and deliberate process, an intensive implementation system, the science of insuring future success.", author:"Pete Johnson" },
    {id:3, text:"Strategy is about setting yourself apart from the competition. It’s not a matter of being better at what you do – it’s a matter of being different at what you do.", author:"Michael Porter" },
    {id:4, text:"The best marketing strategy ever: care.", author:"Gary Vaynerchuck" },
    {id:5, text:"Hope is not a strategy", author:"Vince Lombardi"}
], BrandingDescription = {
    title: "Innovation and Creativity",
    description1: "Unleashing your brands potential is our speciality. Through creative content marketing we are able to connect your brand to it's audience. We understand that your brand represents what you stand for as a promise to your customers. Social first, traditional, paid media, influencers: there are lots of buzz words out there and we know how to create customized solutions based on your brands goals in order to create lasting memorable experiences beyond that of a logo or tagline. \n\nHow individuals and brands communicate with the right stakeholders, audiences, and reporters at the right time is paramount to communicating your message and building brand notoriety. With every update we are able to more intimately understand these interactions enhancing our knowlede and capabilities to deliver whats best for your brand.",
}, BrandingQuoteList = [
    {id:1, text:"A great brand is a story that’s never completely told. A brand is a metaphorical story that connects with something very deep – a fundamental appreciation of mythology. Stories create the emotional context people need to locate themselves in a larger experience.", author:"Scott Bedbury" },
    {id:2, text:"Branding demands commitment; commitment to continual re-invention; striking chords with people to stir their emotions; and commitment to imagination.", author:"Richard Branson" },
    {id:3, text:"The keys to brand success are self-definition, transparency, authenticity and accountability.", author:"Simon Mainwaring" },
    {id:4, text:"If people believe they share values with a company, they will stay loyal to the brand.", author:"Howard Schultz" },
    {id:5, text:"A brand is the set of expectations, memories, stories and relationships that, taken together, account for a consumer’s decision to choose one product or service over another.", author:"Seth Godin" },
    {id:6, text:"If you don’t give the market the story to talk about, they’ll define your brand’s story for you.", author:"David Brier"}
], WebDescription = {
    title: "Digital Experiences",
    description1: "Digital experiences are our specialty. As developers and engineers at heart we are able to make every creative descion with technology architecture not only in mind. Using the newest, fastest, and most secure technologies we are able to create bespoke sophisticated online platforms from the ground up. We take pride in seamless integration, flawless functionality, pleasurable user experiences, and an innate understanding of translating code into your brands voice.",
}, WebQuoteList = [
    {id:1, text:"The next big thing is the one that makes the last big thing usable.", author:"Blake Ross" },
    {id:2, text:"Every great design begins with an even better story.", author:"Lorinda Mamo" },
    {id:3, text:"Design used to be the seasoning you’d sprinkle on for taste; now it’s the flour you need at the start of the recipe.", author:"John Maeda" },
    {id:4, text:"UI is the saddle, the stirrups, & the reins. UX is the feeling you get being able to ride the horse.", author:"Dain Miller" },
], SoftwareDescription = {
    title: "Multiple Devices. Unified Experience.",
    description1: "As platform agnostic software architects there is no software, program, or mobile application we cant handle. We think intuitively about the best platform solutions for your band, audience, and business problems. We love pushing the boundries of modern technology and are always on the forefront of what can be achieved creatively to further reach your audience and increase internal efficiency. Whether we are integrating with existing systems or creating custom solutions we are with you 100% of the way including post launch traning, support, and analysis.",
}, SoftwareQuoteList = [
    {id:1, text:"If we want users to like our software, we should design it to behave like a likeable person: respectful, generous and helpful.", author:"Alan Cooper" },
    {id:2, text:"A clever person solves a problem. A wise person avoids it.", author:"Albert Einstein" },
    {id:3, text:"Programming today is a race between software engineers striving to build bigger and better idiot-proof programs, and the Universe trying to produce bigger and better idiots. So far, the Universe is winning.", author:"Rich Cook" },
    {id:4, text:"Before software can be reusable it first has to be usable.", author:"Ralph Johnson" },
]
