import React from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components/macro'
import ReactHtmlParser from 'react-html-parser'

import { withContext } from '../../../AppContext'
import { pwsSnippet1, pwsSnippet2, pwsSnippet3, pwsSnippet4 } from '../../../../assets/blog/pythonWebScraping'

import BPHead from './BPHead'
import SocialShare from './SocialShare'
import CodeSnippet from './CodeSnippet'
import TeamMemberPreview from '../../../Shared/TeamMemberPreview'
import media from '../../../../theme/DeviceWidth'

const PostContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`

const BodyDiv = styled.div`
    position: relative;
`

const BodyParagraph = styled.div`
    line-height: 1.25;
    transition: all .5s ease;
        -o-transition: all .5s ease;
    padding: 20px 20px 20px 20px;
    text-align: justify;

    ${media.phoneS`
        font-size: 1.2em;
    `}
      
    ${media.phoneM`
        font-size: 1.3em;
    `}
          
    ${media.phoneL`
        font-size: 1.4em;
    `}
          
    ${media.tablet`
        margin: 20px 0px 20px 0px;
        padding: 0px 50px 0px 40px;
    `}
      
    ${media.laptop`
        padding: 0% 10%;
    `}

    ${media.laptopM`
        padding: 0% 12%;
    `}
      
    ${media.laptopL`
        padding: 0% 13%;
    `}
`

const TouchFix = styled.div`
    margin-bottom: -22px;
`

function SingularPost({ articles }) {
    console.log(articles)
    const tutorialPost = articles.map((article) =>
        <PostContainer key={article._id}>
            <Helmet titleTemplate='%s | Blog'>
                <title>{article.fields_title}</title>
                <meta name='description' content={article.meta_description} />
                <meta name='keywords' content={article.meta_keywords} />
                <meta property='og:title' content={article.fields_title} />
                <meta property='og:description' content={article.meta_description} />
                <meta property='og:image' content={article.image} />
                <meta property='og:image:alt' content={article.image_alt} />
                <meta property='og:url' content={`https://www.jpsignum.com/blog/${article._id}`} />
                <meta property='og:type' content='article' />
                <meta property='og:article:author' content={`${article.author_firstName} ${article.author_lastName}`} />
                <meta name='twitter:card' content='summary_large_image' />
                <meta name='twitter:creator' content='@jp_signum' />
            </Helmet>
            <BPHead imgUrl={article.image} imgAlt={article.image_alt} categoryName={article.category_name}
                title={article.fields_title} headline={article.fields_headline} />
            <BodyDiv>
                <BodyParagraph>{ReactHtmlParser(article.fields_body1)}</BodyParagraph>
                <TouchFix>
                    <CodeSnippet code={pwsSnippet1} language='python' />
                </TouchFix>
                <BodyParagraph>{ReactHtmlParser(article.fields_body2)}</BodyParagraph>
                <CodeSnippet
                    leftRadius='4px'
                    rightRadius='4px'
                    language='python'
                    code={pwsSnippet2} />
                <BodyParagraph>{ReactHtmlParser(article.fields_body3)}</BodyParagraph>
                <CodeSnippet radius='4px' language='python' code={pwsSnippet3} />
                <BodyParagraph>{ReactHtmlParser(article.fields_body4)}</BodyParagraph>
                <CodeSnippet radius='4px' language='python' noLineNumbers={true} code={pwsSnippet4} />
                <BodyParagraph>{ReactHtmlParser(article.fields_body5)}</BodyParagraph>
            </BodyDiv>
            <SocialShare
                iconSize={42}
                shareUrl={`https://www.jpsignum.com/blog/${article._id}`}
                fbQuote={`Check out this awesome tutorial on: ${article.fields_title}`}
                title={`Check out this awesome tutorial on: ${article.fields_title}`}
                twitterHashtagsArray={[article.tags[0], article.tags[1], article.tags[2], 'jpSignum']}
                description={`${article.fields_headline}.`}
                media={article.image}
                breakpoints={{
                    1000: {
                        iconSize: 35,
                        shareUrl: `https://www.jpsignum.com/blog/${article._id}`,
                        fbQuote: `Check out this awesome tutorial on: ${article.fields_title}`,
                        title: `Check out this awesome tutorial on: ${article.fields_title}`,
                        twitterHashtagsArray: [`${article.tags[0]}, ${article.tags[1]}, ${article.tags[2]}, 'jpSignum'`],
                        description: `${article.fields_headline}.`,
                        media: `${article.image}`
                    }
                }}
                offsett={400} />
            <TeamMemberPreview
                alt={`${article.author_firstName} ${article.author_lastName} ${article.author_title}`}
                firstName={article.author_firstName}
                lastName={article.author_lastName}
                bio={article.author_bio}
                title={article.author_title}
                id={article.author_slug}
                behanceUri={article.author_urls_behance}
                githubUri={article.author_urls_github}
                linkedInUri={article.author_url_linkedIn}
                twitterUri={article.author_urlstwitter}
                src={article.author_img}
                author={true} />
        </PostContainer>
    )

    return (
        <div>
            {tutorialPost}
        </div>
    )
}

export default withContext(SingularPost);