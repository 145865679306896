import React from 'react'
import styled from 'styled-components/macro'

import { MobileNavDivBeforeWhite } from '../../../Shared/Navigation/navStyleConstants'
import { bpsSN, bpsAF } from '../../../Shared/Constants/breakpoints'

import Navigation from '../../../Shared/Navigation/index'
import Agency from './Agency'
import FLC from '../../../Shared/FLC'
import BlogPostPreview from '../BlogPostPreview'
import Footer from '../../Footer/index'
import styleConstants from '../../../../theme/styleConstants'

import jpBlack from '../../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../../assets/icons/jp_mobile_icon_green.svg'

const Container = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
    background-color: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};
`

function About() {
    return (
        <Container>
            <Navigation height='42px' width='42px' top='16.5px' left='22px'
                color={styleConstants.colors.fontDark}
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName='bm-burger-bars-DARK'
                breakpoints={bpsSN} />
            <Agency />
            <FLC />
            <BlogPostPreview />
            <Footer dark={false} breakpoints={bpsAF} />
        </Container>
    )
}

export default About;