import React from 'react'
import styled from 'styled-components/macro'
import Helmet from 'react-helmet'
import Fade from 'react-reveal'
import { Link } from 'react-router-dom'

import { bpsSN } from '../../Shared/Constants/breakpoints'
import { MobileNavDivBeforeWhite } from '../../Shared/Navigation/navStyleConstants'
import { MetaServices } from '../../Shared/Constants/meta'
import { StrategyDescription, StrategyQuoteList } from './QuoteText'

import media from '../../../theme/DeviceWidth'
import NavigationMobile from '../../Shared/Navigation/index'
import ServicesDisplay from './ServicesDisplay'
import Footer from '../Footer/index'
import styleConstants from '../../../theme/styleConstants'

import jpBlack from '../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../assets/icons/jp_mobile_icon_green.svg'
import JpCreativeBrain from '../../../assets/images/jp_creativeBrain_opt.jpg'

const ServicesContainer = styled.div`
    width: 100%;
    position: relative;
    background-color: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};
    overflow: hidden;
`

const RelativeContainer = styled.div`
    position: relative;
`

const HeroImg = styled.img`
    width: 100%;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        top: 90px;
    `}
        
    ${media.phoneM`
        top: 100px;
    `}
            
    ${media.tablet`
        top: 120px;    
    `}
`

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const HeroTitle = styled.div`
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .06em;
    font-weight: 500;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.5em;
        padding: 110px 0px 10px 20px;
    `}
        
    ${media.phoneM`
        font-size: 1.65em;
        padding: 110px 0px 10px 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.75em;
        padding: 110px 0px 12px 22px;      
    `}
            
    ${media.tablet`
        font-size: 2em;
        padding: 140px 0px 12px 32px;         
    `}
        
    ${media.laptop`
        font-size: 2.1em;
        padding: 140px 100px 12px 100px;           
    `}
        
    ${media.laptopL`
        padding: 140px 120px 12px 120px;       
    `}
`

const HeroDescription = styled.div`
    line-height: 1.25;
    position: relative;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    white-space: pre-wrap;

    ${media.phoneS`
        font-size: 1.1em;
        padding: 0px 20px 10px 20px;
    `}
        
    ${media.phoneM`
        font-size: 1.2em;
        padding: 0px 20px 10px 20px;
    `}
            
    ${media.phoneL`
        font-size: 1.3em;
        padding: 0px 22px 10px 23px;      
    `}
            
    ${media.tablet`
        font-size: 1.5em;
        padding: 0px 32px 10px 33px;         
    `}
        
    ${media.laptop`
        font-size: 1.6em;
        padding: 0px 100px 20px 100px;        
    `}
        
    ${media.laptopL`
        padding: 0px 120px 30px 120px;           
    `}

    ${media.laptopLL`
        padding: 0px 144px 30px 120px;         
    `}
`

const HoverSpan = styled.span`
    transition: color 0.6s ease;
    -o-transition: color .6s ease;
    cursor: pointer;

    :hover {
        color: ${props => props.theme.colors.greenMain};
    }
`

const OSpan = styled.span`
    
`

// entry point to puzzle section -> figure out code and hints first

// const ODiv = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     overflow-x: hidden;
//     background: red;
//     z-index: 100;
// `

// const OuroborosImg = styled.img`
//     /* opacity: 0; */
//     z-index: 12;
//     position: relative;
//     height: 100px;
//     width: 100px;
//     bottom: 40px;
//     left: 10px; 
// `


function Strategy() {
    return (
        <ServicesContainer>
            <Helmet titleTemplate='Services | %s'>
                <title>{MetaServices.titleStrategy}</title>
                <meta name='description' content={MetaServices.descriptionStrategy}></meta>
                <meta name='keywords' content={MetaServices.keywordsStrategy}></meta>
            </Helmet>
            <NavigationMobile height='44px' width='44px' top='17px' left='22px'
                color={styleConstants.colors.fontDark}
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName={'bm-burger-bars-DARK'}
                breakpoints={bpsSN} />
            <RelativeContainer>
                <Fade duration={2000} >
                    <HeroImg src={JpCreativeBrain} alt='Brain with creative ideas flowing' />
                    <FlexContainer>
                        <Link to='/services' style={{ textDecoration: 'none', cursor: 'default' }} >
                            <HeroTitle><HoverSpan>{StrategyDescription.title}</HoverSpan></HeroTitle>
                        </Link>
                        <HeroDescription>
                            {StrategyDescription.description1} 
                            <OSpan> Ouroboros </OSpan> 
                            {StrategyDescription.description2}
                        </HeroDescription>
                        <ServicesDisplay quotes={StrategyQuoteList}/>
                    </FlexContainer>
                </Fade>
            </RelativeContainer>
            <Footer dark='true'/>
        </ServicesContainer>
    )
}

export default Strategy;