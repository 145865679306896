import React from 'react'
import { withBps } from 'react-bps'
import styled from 'styled-components/macro'
import ReactGA from 'react-ga'

const SocialMediaIcon = styled.img`
    position: relative;
    left: ${props => props.left};
    top: ${props => props.top};
    height: ${props => props.height};
    width: ${props => props.width};
    padding: ${props => props.padding};
`

function SocialIcon({ to, src, srcHover, ...rest }) {
    return (
        <ReactGA.OutboundLink to={to} eventLabel={to} target='_blank' style={{ cursor: 'pointer' }}>
            <SocialMediaIcon src={src} {...rest}
                onMouseOver={e => (e.currentTarget.src = srcHover)}
                onMouseOut={e => (e.currentTarget.src = src)} />
        </ReactGA.OutboundLink>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(SocialIcon);