import React from 'react'
import styled from 'styled-components/macro'
import { withBps } from 'react-bps'

const CoreDiv = styled.div`
    position: absolute;
    top: ${props => props.top};
    left: ${props => props.left};
    transform: ${props => props.transform};
    white-space: nowrap;
    text-transform: uppercase;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    letter-spacing: ${props => props.letterSpacing};
    line-height: ${props => props.lineHeight};   
    color: ${props => props.color};
`

const CoreLetterDiv = styled.div`
    display: inline-block;
    opacity: 1;
`

//animate this to have each letter come in and change opacity on scroll

function CoreFeature({ letter1, letter2, letter3, letter4, letter5, letter6, letter7, letter8, ...rest }) {
    return (
        <CoreDiv {...rest}>
            <CoreLetterDiv>{letter1}</CoreLetterDiv>
            <CoreLetterDiv>{letter2}</CoreLetterDiv>
            <CoreLetterDiv>{letter3}</CoreLetterDiv>
            <CoreLetterDiv>{letter4}</CoreLetterDiv>
            <CoreLetterDiv>{letter5}</CoreLetterDiv>
            <CoreLetterDiv>{letter6}</CoreLetterDiv>
            <CoreLetterDiv>{letter7}</CoreLetterDiv>
            <CoreLetterDiv>{letter8}</CoreLetterDiv>
        </CoreDiv>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(CoreFeature);