import React, { Component } from 'react'
import styled from 'styled-components/macro'

import styleConstants from '../../theme/styleConstants'

const DotGridDiv = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`
const getDocumentWidth = () => {
    return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
};

const getDocumentHeight = () => {
    return 2 * Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
};

const vw = getDocumentWidth(),
    vh = getDocumentHeight();

class DotGrid extends Component {
    componentDidMount() {
        this.updateCanvas()
    }

    updateCanvas() {

        const context = this.refs.canvas.getContext('2d')

        // grid
        const drawGrid = () => {
            const cellW = 10,
                cellH = 10;

            // vertical lines
            for (let x = 0; x <= vw; x += cellW) {
                context.moveTo(x, 0); // x, y
                context.lineTo(x, vh);
            }

            // horizontal lines
            for (let y = 0; y <= vh; y += cellH) {
                context.moveTo(0, y); // x, y
                context.lineTo(vw, y);
            }

            context.strokeStyle = styleConstants.colors.fontDark;
            context.stroke();
        }
        drawGrid();

        // mouse position

        // const getMousePos = (canvas, e) => {
        //     let rect = canvas.getBoundingClientRect();
        //     let x = e.clientX - rect.left,
        //         y = e.clientY - rect.top
        //     console.log('coordinate x: ' + x,
        //                 'coordinate y: ' + y)
        // }

        // let canvasElem = document.getElementById('myCanvas');
        // canvasElem.addEventListener('mousemove',(e) => { 
        //     getMousePos(canvasElem, e); 
        // }); 

        //need to wrap canvas element and elents above it in container and use event bublling and getoffsetsum -> see notes for more detailed desc

        // dots
        const drawDots = () => {
            let r = 1.45,
                cw = 40,
                ch = 40;

            for (let x = 20; x < vw; x += cw) {
                for (let y = 20; y < vh; y += ch) {
                    context.fillStyle = styleConstants.colors.greenMain;
                    context.fillRect(x - r / 2, y - r / 2, r, r);
                }
            }
        }
        drawDots();
    }

    render() {
        return (
            <DotGridDiv>
                <canvas
                    id='myCanvas'
                    ref={'canvas'}
                    width={vw}
                    height={vh} />
            </DotGridDiv>
        )
    }
}

export default DotGrid; 