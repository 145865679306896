import { createGlobalStyle } from 'styled-components/macro'

import media from '../theme/DeviceWidth'
import styleConstants from '../theme/styleConstants'

import BebasNeueWOFF from '../assets/fonts/bebas_Neue.woff'
import BebasNeueWOFF2 from '../assets/fonts/bebas_Neue.woff2'

const GlobStyle = createGlobalStyle`
  @font-face {
    font-family: "BebasNeue";
    src: ${BebasNeueWOFF} format('woff'),
          ${BebasNeueWOFF2} format('woff2');

    font-display: fallback;
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    padding: 0;
    margin: 0;
    font-family: 'Roboto';
    font-weight: normal;
    font-style: normal;
  }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 40px;
  height: 32px;
  top: 24px;
  right: 24px;
  z-index: 10 !important;
  transition: all 0.5s ease;
    -o-transition: all 0.5s ease;

  :hover > span span {
    background: ${styleConstants.colors.greenMain} !important; 
    opacity: 1 !important;
  }

  ${media.tablet`
    width: 44px;
    height: 36px;
    top: 28px;
    right: 28px;    
  `}
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  background: ${styleConstants.colors.backgroundLight};
  border-radius: 3px;
  height: 10% !important;

  ${media.tablet`
    height: 12% !important;
  `}
}

.bm-burger-bars-DARK {
  background: ${styleConstants.colors.fontDark};
  border-radius: 3px;
  height: 10% !important;
  transition: all ease 0.5s;
  -o-transition: all .5s ease;

  ${media.tablet`
    height: 12% !important;
  `}
}

/* Position and sizing of clickable cross button  */
.bm-cross-button {
  height: 60px !important;
  width: 60px !important;
  right: 36px !important;
  top: -38px !important;
  
  :hover > span span {
    background: ${styleConstants.colors.greenMain} !important;
  }

  ${media.phoneS`
    height: 62px !important;
    width: 62px !important;
    right: 34px !important;
    top: -38px !important;
  `}

  ${media.phoneM`
    height: 68px !important;
    width: 68px !important;
    right: 34px !important;
    top: -38px !important;
  `}

  ${media.tablet`
    height: 68px !important;
    width: 68px !important;
    right: 47px !important;
    top: -40px !important;
  `}
}

/* Color/shape of close button cross */
.bm-cross {
  background: ${styleConstants.colors.backgroundLight};
  height: 52px !important;
  width: 4px !important;
  border-radius: 4px;

  ${media.tablet`
    height: 60px !important;
    width: 6px !important;
  `}
}


/* General sidebar styles */
.bm-menu {
  padding: 0px 31px 0px 0px;
  margin-top: 80px;
  font-size: 1.3em;

  ${media.tablet`
    margin-top: 120px;
    font-size: 1.8em;
  `}
}

/* Wrapper for item list */
.bm-item-list {
  overflow: hidden !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  text-align: right;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(157, 201, 220, 0.3);
  background: ${styleConstants.colors.fontDark} !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
}

/* styling for the menu items */
.menu-item {
  text-decoration: none;
  color: ${styleConstants.colors.backgroundLight};
  transition: transform 0.875s cubic-bezier(0.7, 0, 0.3, 1)  !important ;
  -o-transition: transform 0.875s cubic-bezier(0.7, 0, 0.3, 1)  !important ;


  :hover {
    color: ${styleConstants.colors.greenMain};
  }

    ${media.phoneS`
      padding-bottom: 20px;
      font-size: 1.2em;
    `}
      
    ${media.phoneM`
      padding-bottom: 30px;
      font-size: 1.4em;
    `}

}

/* hiding link span for landing blog under 1000px cant do it via jsx because of flex container issue -> worth revisiting*/
.LinkSpanBlogHide {
  display: none;
} 

.LinkSpanBlogShow {
  display: contents;
} 

/* styling for html parse of json blog data */
#featuredParagraph {
  background: rgb(0, 0, 0, 0.1);
  margin: 0px -20px 0px -20px;
  transition: all ease 0.5s;
  -o-transition: all .5s ease;
  padding: 2px 18px 2px 18px;
  text-align: justify;
          
    ${media.phoneL`
        font-size: 1.2em;     
    `}
          
    ${media.tablet`
      font-size: 1.1em; 
      margin: 0px -50px 0px -40px;
      padding: 2px 80px 2px 18px;
    `}
      
    ${media.laptop`
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.11);
      margin: 0px 0px 0px 0px; 
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    `}
}

#featuredHL {
  background: rgb(0, 0, 0, 0.1);
  border-left: ${styleConstants.colors.greenMain} 2px solid;
  line-height: 1.05 !important;
  overflow-x: scroll;
  margin: 0px -20px 20px -20px;
  transition: ease 0.5s;
  -o-transition: all .5s ease;
  padding: 14px 30px 14px 18px;
  text-align: justify;
      
    ${media.phoneM`
      padding: 18px 30px 18px 18px;
    `}
          
    ${media.phoneL`
      font-size: 1.2em;   
    `}
          
    ${media.tablet`
      font-size: 1.1em; 
      margin: 0px -50px 20px -40px;
    `}
      
    ${media.laptop`
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.11);
      margin: 0px 0px 20px 0px; 
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;    
    `}
}

#featuredHLtop {
  padding: 14px 30px 14px 18px;
  margin: -13px -20px 20px -20px;
  background: rgb(0, 0, 0, 0.1);
  border-left: ${styleConstants.colors.greenMain} 2px solid;
  line-height: 1.05 !important;
  overflow-x: scroll;
  transition: ease 0.5s;
  -o-transition: all .5s ease;
  text-align: justify;

      
    ${media.phoneM`
      padding: 18px 30px 18px 18px;
    `}
          
    ${media.phoneL`
      font-size: 1.2em;   
    `}
          
    ${media.tablet`
      font-size: 1.1em; 
      margin: -13px -50px 20px -40px;     
    `}
      
    ${media.laptop`
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.11);
      margin: -13px 0px 20px 0px;    
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;    
    `}
}

#horizSpaceDiv {
  width: 100%;
  height: 20px;
}

#tab1 {
  margin-left: 10px;
}

#tab2 {
  margin-left: 20px;
}

#tab3 {
  margin-left: 30px;
}

/* arrow scroll down animation */
.scrollDownBlink1 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
    
    @keyframes sdb07 {
    0% {
        opacity: 0;
        }
    50% {
        opacity: 1;
        }
    100% {
        opacity: 0;
        }
    }
}

.scrollDownBlink2 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
  animation-delay: .15s;
    -o-animation-delay: .15s;
}

.scrollDownBlink3 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
  animation-delay: .3s;
    -o-animation-delay: .3s;
  
}


/* design development svg animations */
.arrowFade {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
    -o-animation-delay: 0.4s;
    
    @keyframes sdb07 {
    0% {
        opacity: 0;
        }
    50% {
        opacity: 1;
        }
    100% {
        opacity: 0;
        }
    }
}

.arrowFade2 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
  animation-delay: 0.4s;
    -o-animation-delay: 0.4s;
}

.arrowFade3 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
  animation-delay: 0.8s;
    -o-animation-delay: 0.8s;
}

.arrowFade4 {
  animation: sdb07 2s infinite;
    -o-animation: sdb07 2s infinite;
  animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
}

.arrowMoveLeft1 {
    ${media.phoneS`
      animation: spnPS 2.6s infinite;
      -o-animation: spnPS 2.6s infinite;
    `}
        
    ${media.phoneM`
      animation: spnP 2.6s infinite;
      -o-animation: spnP 2.6s infinite;
    `}
            
            
    ${media.tablet`
    animation: spnT 3.6s infinite;
      -o-animation: spnT 3.6s infinite;    
    `}
        

    @keyframes spnPS {
      0% {
          transform: translate(0px, 0px) rotate(180deg);
          opacity: 0;
          }
      50% {
          transform: translate(-40px, 0px) rotate(-90deg);
          opacity: 1;
          }
      100% {
        transform: translate(-80px, 0) rotate(-90deg);
        opacity: 0;
          }
    }

    @keyframes spnP {
      0% {
          transform: translate(0px, 0px) rotate(180deg);
          opacity: 0;
          }
      50% {
          transform: translate(-50px, 0px) rotate(-90deg);
          opacity: 1;
          }
      100% {
        transform: translate(-100px, 0) rotate(-90deg);
        opacity: 0;
          }
    }

    @keyframes spnT {
      0% {
          transform: translate(0px, 0px) rotate(180deg);
          opacity: 0;
          }
      50% {
          transform: translate(-106px, 0px) rotate(-90deg);
          opacity: 1;
          }
      100% {
        transform: translate(-212px, 0) rotate(-90deg);
        opacity: 0;
          }
    }
}

.arrowMoveRight1 {
    ${media.phoneS`
      animation: movrPS 2s infinite;
      -o-animation: movrPS 2s infinite;
    `}
        
    ${media.phoneM`
      animation: movrP 2s infinite;
      -o-animation: movrP 2s infinite;
    `}
            
            
    ${media.tablet`
    animation: movrT 3s infinite;
      -o-animation: movrT 3s infinite;      
    `}
        
  
  @keyframes movrPS {
    0% {
        transform: translate(0px, 0px) rotate(90deg);
        }
    50% {
        transform: translate(15px, 0px) rotate(90deg);
        opacity: 1;
        }
    100% {
        transform: translate(30px, 0px) rotate(90deg);
        opacity: 0;
        }
    }

  @keyframes movrP {
    0% {
        transform: translate(0px, 0px) rotate(90deg);
        }
    50% {
        transform: translate(20px, 0px) rotate(90deg);
        opacity: 1;
        }
    100% {
        transform: translate(40px, 0px) rotate(90deg);
        opacity: 0;
        }
    }

    @keyframes movrT {
    0% {
        transform: translate(0px, 0px) rotate(90deg);
        }
    50% {
        transform: translate(40px, 0px) rotate(90deg);
        opacity: 1;
        }
    100% {
        transform: translate(80px, 0px) rotate(90deg);
        opacity: 0;
        }
    }
} 

.circleRotate {
  animation: sdb10 24s infinite;
    -o-animation: sdb10 24s infinite;
    
    @keyframes sdb10 {
    0% {
        transform: rotate(0deg);
        }
    100% {
        transform: rotate(360deg);
        }
    }
}

/* selected blog filter styles */

.isSelected {
  color: ${styleConstants.colors.greenMain};
}

.isNotSelected {
  
}

/* strange menu link gold ring focus fix */

a {
  color: ${styleConstants.colors.fontDark};
  outline: none;
  
  :focus {
    outline:  none !important;
    outline-color: none !important;
    outline-style: none !important;
    outline-width: none !important;
    -webkit-focus-ring-color: none !important;
  }
}

button {
  outline: none;

  :focus {
    outline:  none !important;
    outline-color: none !important;
    outline-style: none !important;
    outline-width: none !important;
    -webkit-focus-ring-color: none !important;
  }
}

/* tutorial badges */
.labelLanding { 
  transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  position: relative;
  top: 4px; 
  text-shadow: none; 
  font-size: 0.6em !important; 
  font-weight: normal; 
  background-color: lightcoral;
  color: ${styleConstants.colors.backgroundLight};
  border-radius: 0; 
  margin-left: 9px;
  padding: 4px 4px 2px 2px;

  :before {
    position: absolute; 
    left: -14px; 
    top: 0; 
    display: inline-block; 
    content: ""; 
    border: 9px solid transparent; 
    border-width: 9.5px 7px; 
    border-right-color: lightcoral;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    }

    ${media.phoneL`
      :before {
        border-width: 10px 7px;   
      }         
    `}  

    ${media.tablet`
      :before {
        border-width: 11px 7px;  
      }         
    `}

    ${media.laptop`
      :before {
        border-width: 9.5px 7px;  
      }         
    `}
}

.labelPP { 
  border-radius: 0; 
  text-shadow: none; 
  font-weight: normal; 
  padding: 2px 4px 2px 2px;
  background-color: lightcoral;
  color: ${styleConstants.colors.backgroundLight};
  top: 4px;
  position: relative; 
  margin-left: 9px ;
  font-size: 0.6em !important; 
  transition: all ease 0.5s;
  -o-transition: all .5s ease;

  :before {
    display: inline-block; 
    content: ""; 
    position: absolute; 
    left: -14px; 
    top: 0; 
    border: 9px solid transparent; 
    border-width: 11px 7px; 
    border-right-color: lightcoral;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    }

    ${media.tablet`
      :before {
        border-width: 12px 7px;  
      } 
    `}   

    ${media.tablet`
      :before {
        border-width: 11px 7px;  
      }  
    `}
}

.labelBP { 
  border-radius: 0; 
  text-shadow: none; 
  font-weight: normal; 
  padding: 4px 4px 2px 2px;
  background-color: lightcoral;
  color: ${styleConstants.colors.backgroundLight};
  top: 4px;
  position: relative; 
  margin-left: 9px ;
  font-size: 0.6em !important; 
  transition: all ease 0.5s;
  -o-transition: all .5s ease;

  :before {
    display: inline-block; 
    content: ""; 
    position: absolute; 
    left: -14px; 
    top: 0; 
    border: 9px solid transparent; 
    border-width: 10px 7px;  
    border-right-color: lightcoral;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    }
}

.labelSP { 
  border-radius: 0; 
  text-shadow: none; 
  font-weight: normal; 
  padding: 4px 4px 2px 2px;
  background-color: lightcoral;
  color: ${styleConstants.colors.backgroundLight};
  top: 4px;
  position: relative; 
  margin-left: 9px ;
  font-size: 0.6em !important; 
  transition: all ease 0.5s;
  -o-transition: all .5s ease;

  :before {
    display: inline-block; 
    content: ""; 
    position: absolute; 
    left: -14px; 
    top: 0; 
    border: 9px solid transparent; 
    border-width: 11px 7px;  
    border-right-color: lightcoral;
    transition: all ease 0.5s;
    -o-transition: all .5s ease;
    }

    ${media.phoneL`
      :before {
        border-width: 11.5px 7px;  
      } 
    `}    

      ${media.laptop`
        padding: 6px 4px 2px 2px;
        
        :before {
          border-width: 14.5px 7px;  
      } 
    `} 
}

/* services  */

.serviceOverlay {
  
}

.servicesDarkIcon {
 
}

.servicesArrowSpan {
  
}

.servicesHeader {

}

.servicesList {
  
}

/* Social Sidebar */

.RelativeSSHidden {
  opacity: 0;
}

.RelativeSSVisible {
  position: relative;
  padding: 80px 0px 92px 0px;
  transition: all .5s ease;
    -o-transition: all .5s ease;

  ${media.tablet`
    padding: 100px 0px 100px 0px; 
  `}

  ${media.laptop`
    position: fixed;
  `}
}

/* puzzle stuff */

.ouroborosOverlay {
  
}


`

export default GlobStyle