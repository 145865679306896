import React from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import { bpsSL } from '../../Shared/Constants/breakpoints'
import media from '../../../theme/DeviceWidth'
import LinkSpanW from '../../Shared/LinkSpanW'
import { psServiceList } from '../../Shared/Constants/paragraphStrings'

const RelativeContainer = styled.div`
    position: relative;
    transition:all ease 0.5s; 
    -o-transition: all .5s ease;

    ${media.laptop`
        padding: 0px 180px 0px 160px;      
    `}

    ${media.laptop`
        padding: 0px 220px 0px 160px;      
    `}
`

const ServicesList = styled.ul`
    text-align: left;
    margin: -20px 10px 20px -10px;
    transition: all .5s ease;
    -o-transition: all .5s ease;

    ${media.tablet`
        margin: -40px 20px 20px 0px;
        padding-bottom: 20px;
    `}  
`
const ServicesListItem = styled.li`
    line-height: 1.5;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;
    
    ${media.phoneS`
        font-size: 1em;
    `}
        
    ${media.phoneM`
        font-size: 1.1em;
    `}
            
    ${media.phoneL`
        font-size: 1.2em;    
    `}
            
    ${media.tablet`
        font-size: 1.7em;  
    `}
`

function ServiceListComponent() {
    return (
        <RelativeContainer>
            <Fade up duration={2000}>
                <ServicesList>
                    <ServicesListItem>{psServiceList.webP}</ServicesListItem>
                    <ServicesListItem>{psServiceList.contentP}</ServicesListItem>
                    <ServicesListItem>{psServiceList.databaseP}</ServicesListItem>
                    <ServicesListItem>{psServiceList.mobileP}</ServicesListItem>
                </ServicesList>
            </Fade>
            <Fade duration={2000}>
                <LinkSpanW
                    to='/services'
                    spanText='LEARN MORE ->'
                    fontSize='1.2em'
                    left='184px'
                    bottom='10px'
                    letterSpacing='0.7px'
                    breakpoints={bpsSL} />
            </Fade>
        </RelativeContainer>
    )
}

export default ServiceListComponent;