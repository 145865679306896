export const psLanding = {
    marketingP: "Every digital interaction counts – be it on a website, in a mobile app, or through email – because companies will know exactly how to engage with their customers.",
    consultationP: "It’s a world that’s possible today – and we can take you there with our data-driven marketing and consultation services.",
    agencyP: "JP Signum is a boutique design and digital marketing agency whose focus is elevating our clients brands through",
    blogP: "to find out whats going on around our community, or brush up on your tech skills in the tutorial section with popular tags such as:"
}

export const SocialAltStrings = {
    FbAlt: 'Facebook Icon',
    InstaAlt: 'Instagram Icon'
}
  
export const SocialHrefs = {
    FbHref: '//www.facebook.com/jpsignum',
    InstaHref: '//www.instagram.com/jp_signum'
}

export const psServiceList = {
    webP: "Cutting-edge web applications made with powerful and responsive user interfaces.",
    contentP: "Bespoke content management systems.",
    databaseP: "Databases designed with complete scalability in mind.",
    mobileP: "Native mobile cross platform development capabilities - meaning less dev time and more time focusing on your platform initiatives post launch."
}
  
export const psAgency = {
    agencyS2: "We are a boutique design and digital marketing agency who's focus is elevating our clients brands through",
    agencyP1: "Signum is the latin word for sign and where the word design originates, along with our namesake. We believe its always import to know where you come from especially in periods of growth and transition. Our result and data driven solutions allow us to make that process as comfortable as possible.",
    agencyP3: "Our mission has always been the same: to be involved in causes, organizations, and companies that make a difference in their community and the world. Fresh thinking is always required, but passion is the driving force in all projects we take on.",
    agencyP4: "By conceiving, developing, and designing innovate creative material we are able to provide creative digital media solutions focusing on website/mobile application design & development, marketing & digital strategy, branding & design, and advertising.",
}