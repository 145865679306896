export const Meta = {
    title: "JP Signum Digital Marketing Agency",
    description: "A boutique creative agency specializing in developing dynamic and responsive websites while also providing digital media solutions focusing on website design and development, mobile app design and development, marketing strategy consultation, social media management, SEO, small business consultation, and branding/graphic design solutions.",
    keywords: "web development, web design, website design, mobile app design, mobile app, mobile applicaiton development, marketing consultation, marketing strategy, digital strategy, consulting, full-stack development, wordpress development, cms, content mangament system, php, python, javascript, react, react development, javascript development, iOS development, Android development, marketing, digital media, graphic design, responsive website development, digital marketing, branding"
}, MetaTeamPJM ={
    title: "Patrick Morgan",
    description: "Patrick is a full stack engineer specializing in creating dynamic mobile and web applications. As our creative director you can also find him managing projects across the design and marketing teams.",
    keywords: "Full Stack Engineer, Computer Science, Programmer, React Developer, React, Full Stack, Back End, Front End, Developer, Engineer, Engineering, Agency, Creative Director, Marketing, Digital Marketing, Advertising, Patrick Morgan, JP Signum, Design, Designer, Graphic Design, Graphic Designer, Creative Director"
}, MetaBlogList = {
    title: "Design, Development, Marketing",
    description: "A boutique creative agency specializing in developing dynamic and responsive websites while also providing digital media solutions focusing on website design and development, mobile app design and development, marketing strategy consultation, social media management, SEO, small business consultation, and branding/graphic design solutions.",
    keywords: "web development, web design, website design, mobile app design, mobile app, mobile applicaiton development, marketing consultation, marketing strategy, digital strategy, consulting, full-stack development, wordpress development, cms, content mangament system, php, python, javascript, react, react development, javascript development, iOS development, Android development, marketing, digital media, graphic design, responsive website development, digital marketing, branding"
}, MetaServices = {
    titleServicesFull: "Design, Development, Marketing",
    description: "A boutique creative agency specializing in developing dynamic and responsive websites while also providing digital media solutions focusing on website design and development, mobile app design and development, marketing strategy consultation, social media management, SEO, small business consultation, and branding/graphic design solutions.",
    keywords: "web development, web design, website design, mobile app design, mobile app, mobile applicaiton development, marketing consultation, marketing strategy, digital strategy, consulting, full-stack development, wordpress development, cms, content mangament system, php, python, javascript, react, react development, javascript development, iOS development, Android development, marketing, digital media, graphic design, responsive website development, digital marketing, branding",
    titleStrategy: "Strategy",
    descriptionStrategy: 'Abraham Lincoln once said that, given 8 hours to chop down a tree, he would spend six sharpening his axe. We create strategies that are agile, flexible, and allow for real-time interactions. As strategists, we are committed to understanding the motivations that drive audience behavior. As communicators, we work to creatively influence that behavior. Through in depth research we are able to guide clients by making swift adjustments based on data driven reults and insights. Advances in analytics have armed us with mountains of data, that we use to align your hopes and dreams into a reality. "There once was a serpent who only traveled in one direction. Always forward, never backward..."As the story of the Ouroboros goes this is not the end but the begining of a new cycle. Post launch we will adjust and redefine our benchmarks of sucess by constantly analyzing your goals and objectives in order to stay focused while exploring additional sucessful oppourtunities.',
    keywordsStrategy: "strategy, branding strategy, marketing strategy, social media marketing, media, social, market research, integrated marketing, analysis, optimization, content marketing, hubspot, sales funnel, digital strategy, seo, sem, ppc, social media",
    titleBranding: "Branding",
    descriptionBranding: "Unleashing your brands potential is our speciality. Through creative content marketing we are able to connect your brand to it's audience. We understand that your brand represents what you stand for as a promise to your customers. Social first, traditional, paid media, influencers, theres lots of buzz words out there and we know how to create customized solutions based on your brands goals in order to create lasting memorable experiences beyond that of a logo or tagline. How individuals and brands communicate with the right stakeholders, audiences, and reporters at the right time is paramount to communicating your message and building brand notoriety. With every update we are able to more intimately understand these interactions enhancing our knowlede and capabilities to deliver whats best for your brand.",
    keywordsBranding: "branding, influencer outreach, influencer, social media influence, social, social media, media, brand development, naming, messaging, logo, brand logo, identity, brand identity, flyer, print, rebranding, style guides, collateral, packaging, graphic design",
    titleWeb: "Web",
    descriptionWeb: "Digital experiences are our specialty. As developers we are able to make every creative descion with technology architecture not only in mind but at the forefront. Using the newest, fastest, and safest technologies we are able to create bespoke sophisticated online platforms from the ground up. We take pride in seamless integration, flawless functionality, pleasurable user experiences, and an innate understanding of translating code into your brands voice.",
    keywordsWeb: "web, website, full stack, e-commerce, e commerce, wordpress, cms, responsive, qa, user testing, user experience, ux, crm, customer relations manager, customer relations, cms, content managment system, bespoke",
    titleSoftware: "Software",
    descriptionSoftware: "As platform agnostic software architechs there is no software, program, or mobile application we cant handle. We think intuitively about the best platform solutions for your band, audience, and business problems. We love pushing the boundries of modern technology and are always on the forefront of what can be achieved creatively to further reach your audience and increase internal efficiency. Wheater we are integrating with existing systems or creating custom solutions we are with you 100% of the way including post launch traning, support, and analysis.",
    keywordsSoftware: "software, custom solutions, user interface, ui, mobile applications, android, apple, native, amazon",
}