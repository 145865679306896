import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import media from '../../../../theme/DeviceWidth'
import LinkSpan from '../../../Shared/LinkSpan'

const PostContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    padding: 20px 0px 20px 0px;

    ${media.tablet`
        display: grid;
        grid-template-columns: 220px auto;
    `}

    ${media.laptop`
        grid-template-columns: 380px auto;
        padding-top: 50px;
    `}

    ${media.laptopL`
        grid-template-columns: 420px auto;
    `}

    ${media.laptopLM`
        margin-left: 5%;
    `}
`

const ImgDiv = styled.div`
    width: 100%;
`

const FeaturedImg = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    width: 90%;
    margin-left: 18px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.21);

    ${media.tablet`
        width: 100%;
    `}
`

const PostDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

    ${media.laptop`
        max-width: 480px;
        margin: 66px 0px 0px 6px;
    `}

    ${media.laptopL`
        max-width: 540px;
        margin: 115px 0px 0px 8px;
    `}
`

const DatePublished = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    font-weight: 500;
    color: ${props => props.theme.colors.greenMain};

    ${media.phoneS`
        font-size: 1.4em;
        padding: 8px 0px 8px 17px;
    `}
      
    ${media.phoneM`
        font-size: 1.5em;
    `}
          
    ${media.phoneL`
        font-size: 1.6em;    
    `}
          
    ${media.tablet`
        font-size: 1.7em;
        padding: 0px 0px 0px 17px;  
    `}
      
    ${media.laptop`
        font-size: 1.6em;  
    `}
`
const Title = styled.div`
  transition: all .5s ease;
    -o-transition: all .5s ease;
    font-size: 1.6em;
    padding: 0px 18px 8px 20px;
      
    ${media.phoneM`
        font-size: 1.7em;
        padding: 0px 0px 8px 20px;
    `}
          
    ${media.tablet`
        font-size: 1.8em;
        font-weight: 550;
        padding: 0px 12px 10px 20px;  
    `}
      
    ${media.laptop`
        font-size: 1.7em;
        font-weight: 550;    
    `}
`

const Headline = styled.div`
  transition: all .5s ease;
  -o-transition: all .5s ease;

    ${media.phoneS`
        font-size: 1.1em;
        padding: 0px 20px 20px 20px;
    `}
      
    ${media.phoneM`
        font-size: 1.2em;
        padding: 0px 22px 20px 22px;
    `}
          
    ${media.phoneL`
        font-size: 1.3em;    
    `}
          
    ${media.tablet`
        font-size: 1.4em;    
    `}
      
    ${media.laptop`
        font-size: 1.2em;    
    `}
`

function PostPreview({
    id,
    article: { published_on, image, image_alt, fields_title, category_name, fields_headline }
}) {
    const formattedPostDate = moment(published_on).format('MMMM YYYY')
    return (
        <PostContainer>
            <ImgDiv>
                <FeaturedImg src={image} alt={image_alt} />
            </ImgDiv>
            <PostDiv>
                <DatePublished>&mdash; {formattedPostDate}</DatePublished>
                <Title> {fields_title} <span className='labelPP arrowed-in'> {` ${category_name}`} </span> </Title>
                <Headline> {fields_headline}
                    <span> </span>
                    <LinkSpan to={'/blog/' + id} id={id} spanText='. . .' />
                </Headline>
            </PostDiv>
        </PostContainer>
    )
}

export default PostPreview;