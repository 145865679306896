import React from 'react'
import Highlight, { defaultProps } from 'prism-react-renderer'
import styled from 'styled-components/macro'
import theme from 'prism-react-renderer/themes/oceanicNext'

import media from '../../../../theme/DeviceWidth'

const Pre = styled.pre`
    text-align: left;
    margin: 1em 0;
    padding: 1em 1em 1em 1.2em;
    overflow-x: scroll;

    & .token-line {
        line-height: 1.3em;
        height: 1.3em;
    }

    ${media.laptop`
        box-shadow: 0px 3px 15px rgba(0, 0, 0, .2);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: ${props => props.radius};
        border-bottom-right-radius: ${props => props.radius};    
    `}
`

const LineNo = styled.span`
    display: inline-block;
    width: 2em;
    user-select: none;
    opacity: 0.3;
`

const FixPaddingDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

    ${media.phoneS`
        font-size: 1em;
    `}
      
    ${media.phoneM`
        font-size: 1.1em;
    `}
          
    ${media.phoneL`
        font-size: 1.2em;     
    `}      
      
    ${media.laptop`
        padding: 0% 10%;
    `}

    ${media.laptopM`
        padding: 0% 12%;
    `}
      
    ${media.laptopL`
        padding: 0% 13%;
    `}
`

function CodeSnippet({ code, radius, noLineNumbers, language }) {
    return (
        <FixPaddingDiv>
            <Highlight {...defaultProps} theme={theme} code={code} language={language}>
                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <Pre radius={radius} className={className} style={style}>
                        {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                                {!noLineNumbers && <LineNo>{i + 1}</LineNo>}
                                {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
                            </div>
                        ))}
                    </Pre>
                )}
            </Highlight>
        </FixPaddingDiv>
    )
}

export default CodeSnippet;