import React from 'react'
import styled from 'styled-components/macro'

import media from '../../../theme/DeviceWidth'

import DesDev1 from '../../../assets/images/jp_desdev1.svg'
import DesDevArrow from '../../../assets/images/jp_desdevArrow1.svg'
import DesDevCircle from '../../../assets/images/jp_desdevCircle.svg'

const DesDevDiv = styled.div`
    position: relative;
    width: 100%;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        margin: -14px 0px 0px 2px;
    `}
      
    ${media.phoneM`
        margin: -14px 0px 0px 2px;
    `}
          
          
    ${media.tablet`
        padding: 20px 0px 20px 0px;
    `}
`

const DesDevSvg = styled.img`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        width: 300px;
        padding-top: 40px;
    `}
      
    ${media.phoneM`
        width: 360px;
        padding-top: 40px;
    `}
          
    ${media.phoneL`
        width: 400px;
        padding-top: 40px;  
    `}
          
    ${media.tablet`
        width: 700px;
        padding: 30px 0px 20px 0px;   
    `}
`

const DesDevArrowSvg = styled.img`
    position: absolute;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        height: 7px;
        width: 7px;
    `}
      
    ${media.phoneM`
        height: 8px;
        width: 8px;
    `}
          
    ${media.phoneL`
        height: 9px;
        width: 9px; 
    `}
          
    ${media.tablet`
        height: 12px;
        width: 12px;   
    `}
`

const DesDevCircle1 = styled.img`
    position: absolute;
    z-index: -1;
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        height: 60px;
        width: 60px;
        left: 208px;
        top: 54px;
    `}
      
    ${media.phoneM`
        height: 70px;
        width: 70px;
        left: 240px;
        top: 58px;
    `}
          
    ${media.phoneL`
        height: 80px;
        width: 80px;
        left: 260px;
        top: 60px;  
    `}
          
    ${media.tablet`
        height: 130px;
        width: 130px;
        left: 484px;
        top: 86px;     
    `}
`

const DesDevArrowSvg1 = styled(DesDevArrowSvg)`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 33px;
        top: 166px;
    `}
      
    ${media.phoneM`
        left: 41px;
        top: 190px;
    `}
          
    ${media.phoneL`
        left: 45px;
        top: 208px; 
    `}
          
    ${media.tablet`
        left: 81px;
        top: 340px;    
    `}
`

const DesDevArrowSvg2 = styled(DesDevArrowSvg)`
    transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 74px;
        top: 80px;
    `}
      
    ${media.phoneM`
        left: 90px;
        top: 90px;
    `}
          
    ${media.phoneL`
        left: 99px;
        top: 95px; 
    `}
          
    ${media.tablet`
        left: 175px;
        top: 150px;   
    `}
`

const DesDevArrowSvg3 = styled(DesDevArrowSvg)`
    transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 260px;
        top: 130px;
    `}
      
    ${media.phoneM`
        left: 314px;
        top: 146px;
    `}
          
    ${media.phoneL`
        left: 350px;
        top: 154px;   
    `}
          
    ${media.tablet`
        left: 613px;
        top: 256px;    
    `}
`

const DesDevArrowSvg4 = styled(DesDevArrowSvg)`
    transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 233px;
        top: 170px;
    `}
      
    ${media.phoneM`
        left: 280px;
        top: 196px;
    `}
          
    ${media.phoneL`
        left: 310px;
        top: 216px;
    `}
          
    ${media.tablet`
        left: 540px;
        top: 358px;   
    `}
`

const DesDevArrowSvg5 = styled(DesDevArrowSvg)`
    transition: all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 180px;
        top: 194px;
    `}
      
    ${media.phoneM`
        left: 212px;
        top: 226px;
    `}
          
    ${media.phoneL`
        left: 238px;
        top: 244px;
    `}
          
    ${media.tablet`
        left: 420px;
        top: 410px;   
    `}
`

const DesDevArrowSvg6 = styled(DesDevArrowSvg)`
    transition:all ease 0.5s;
    -o-transition: all .5s ease;

    ${media.phoneS`
        left: 138px;
        top: 56px;
    `}
      
    ${media.phoneM`
        left: 166px;
        top: 60px;
        
    `}
          
    ${media.phoneL`
        left: 184px;
        top: 63px;   
    `}
          
    ${media.tablet`
        left: 325px;
        top: 91px;    
    `}
`

function DesDevSvgComponent() {
    return (
        <DesDevDiv>
            <DesDevSvg
                src={DesDev1}
                alt='Design & Development Computer Digital Media Icon' />
            <DesDevArrowSvg1
                src={DesDevArrow}
                alt='desdevarrow1'
                className='arrowFade' />
            <DesDevArrowSvg2
                src={DesDevArrow}
                alt='desdevarrow2'
                className='arrowFade2' />
            <DesDevArrowSvg3
                src={DesDevArrow}
                alt='desdevarrow3'
                className='arrowFade3' />
            <DesDevArrowSvg4
                src={DesDevArrow}
                alt='desdevarrow4'
                className='arrowFade4' />
            <DesDevArrowSvg5
                src={DesDevArrow}
                alt='desdevarrow5'
                className='arrowMoveLeft1' />
            <DesDevArrowSvg6
                src={DesDevArrow}
                alt='desdevarrow6'
                className='arrowMoveRight1' />
            <DesDevCircle1
                src={DesDevCircle}
                alt='desdevcircle'
                className='circleRotate' />
        </DesDevDiv>
    )
}

export default DesDevSvgComponent;