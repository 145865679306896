import React from 'react'
import styled from 'styled-components/macro';
import { withBps } from 'react-bps'

const CoreDiv = styled.div`
    text-transform: uppercase;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    position: absolute;
    font-weight: ${props => props.fontWeight};
    font-size: ${props => props.fontSize};
    letter-spacing: ${props => props.letterSpacing};
    line-height: ${props => props.lineHeight};   
    color: ${props => props.color};
    top: ${props => props.top} ;
    left: ${props => props.left};
`

const CoreLetterDiv = styled.div`
    display: block;
    opacity: 1;
`

function CoreFeature(props) {
    return (
        <CoreDiv
            fontWeight={props.fontWeight}
            fontSize={props.fontSize}
            letterSpacing={props.letterSpacing}
            lineHeight={props.lineHeight}
            color={props.letterColor}
            top={props.top}
            left={props.left} >
            <CoreLetterDiv>{props.letter1}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter2}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter3}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter4}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter5}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter6}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter7}</CoreLetterDiv>
            <CoreLetterDiv>{props.letter8}</CoreLetterDiv>
        </CoreDiv>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(CoreFeature);