import React from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import InlineLinkSpan from '../Shared/InlineLinkSpan'
import styleConstants from '../../theme/styleConstants'
import media from '../../theme/DeviceWidth'

const ProjectPicDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    background: ${props => props.theme.colors.fontDark};
    
    ${media.phoneS`
        width: 100%;
        height: 220px;
    `}
        
    ${media.phoneM`
        height: 250px;
    `}
            
    ${media.phoneL`
        height: 290px;
    `}
        
    ${media.laptop`
        height: 420px;       
    `}
`

const ProjectsPic = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    opacity: 0.15;

    ${media.phoneS`
        width: 100%;
    `}
            
    ${media.tablet`
        height: 320px;   
    `}
        
    ${media.laptop`
        height: 380px;        
    `}
`

const ProjectsDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    z-index: 2;
    color: ${props => props.theme.colors.backgroundLight};
    opacity: 1;
    font-size: 1.9em;
    text-align: center;
    padding: 0 34px;

    ${media.phoneM`
        font-size: 2.2em;
    `}
            
    ${media.phoneL`
        font-size: 2.3em;
        padding: 30px 22px 0px 22px;    
    `}
            
    ${media.tablet`
        padding: 0px 20px 0px 32px;      
    `}
        
    ${media.laptop`
        font-size: 2.5em;
        padding: 0px 50px 0px 52px;  
    `}
`

function ProjectsLink({ src, alt, span1, to, linkSpanText, span2, span3}) {
    return (
        <Fade duration={2000}>
            <ProjectPicDiv>
                <ProjectsPic src={src} alt={alt} />
                <ProjectsDiv>
                    <span>{span1}</span>
                    <InlineLinkSpan to={to} text={linkSpanText} color={styleConstants.colors.greenMain}></InlineLinkSpan>
                    <span>{span2}</span>
                    <div>{span3}</div>
                </ProjectsDiv>
            </ProjectPicDiv>
        </Fade>
    )
}

export default ProjectsLink;



