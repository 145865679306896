import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

const StyledSpan = styled.span`
    padding: 5px 6px 0px 6px;
    background: ${props => props.background};
    transition: all ease .5s;
    -o-transition: all .5s ease;
    color: #fff;
    position: relative;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    font-size: ${props => props.fontSize};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    letter-spacing: ${props => props.letterSpacing};

    :hover{
        background: ${props => props.theme.colors.greenMain};
        cursor: pointer;
    }
`

const ArrowSpan = styled.span`
    color: ${props => props.color};
`

function ServiceLink(props) {
    return (
        <Link to={props.to} style={{textDecoration: 'none'}}>
            <StyledSpan
                background={props.background}
                fontSize={props.fontSize}
                left={props.left}
                bottom={props.bottom}
                letterSpacing={props.letterSpacing}>{props.spanText}<ArrowSpan className='servicesArrowSpan' color={props.color}> -></ArrowSpan></StyledSpan>
        </Link>
    )
}

export default ServiceLink;