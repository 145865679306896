import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import { withBps } from 'react-bps'

const StyledSpan = styled.span`
    padding: 6px 6px 0px 6px;
    background: ${props => props.theme.colors.greenMain};
    transition: all ease .5s;
    -o-transition: all .5s ease;
    color: #F4FAFF;
    position: relative;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    font-size: ${props => props.fontSize};
    left: ${props => props.left};
    bottom: ${props => props.bottom};
    letter-spacing: ${props => props.letterSpacing};
    box-shadow: 0px 3px 15px rgba(255, 255, 255, 0.11);
    cursor: pointer;

    :hover{
        background: ${props => props.theme.colors.fontDark};
        box-shadow: 0px 3px 15px rgba(255, 255, 255, 0);
    }

    
`

//redo this destrcucturing ****************************
//need to remove from mobile screens

function LinkSpanW({ spanText, ...rest }) {
    return (
        <Link {...rest} style={{ textDecoration: 'none', position: 'relative' }}>
            <StyledSpan {...rest}>{spanText}</StyledSpan>
        </Link>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(LinkSpanW);