import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import moment from 'moment'
import Fade from 'react-reveal'

import { withContext } from '../../AppContext'
import { sortByFrequency, getNested, flattenTagArray } from '../../Shared/Constants/helperFunctions'
import { bpsLCFBlog, bpsLSBP } from '../../Shared/Constants/breakpoints'
import { psLanding } from '../../Shared/Constants/paragraphStrings'

import styleConstants from '../../../theme/styleConstants'
import CoreFeature from '../../Shared/CoreFeature'
import DotGrid from '../../Shared/DotGrid'
import media from '../../../theme/DeviceWidth'
import InlineLinkSpan from '../../Shared/InlineLinkSpan'
import LinkSpan from '../../Shared/LinkSpan'

const FlexLinkContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    background: ${props => props.theme.colors.fontDark};
    color: ${props => props.theme.colors.backgroundLight};
    display: none;

    ${media.laptop`
        display: block;
        margin: 30px 0px -30px 0px;
    `}
`

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.laptopL`
        margin: -40px 40px 0px 0px;
    `}

    ${media.laptopLM`
        margin: -80px 80px 0px 0px;
    `}

    ${media.laptopLL`
        margin: -100px 120px 0px 0px;
    `}
`

const RelativeContainerLeft = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    margin: 0px 40px 0px 44px;

    ${media.laptopL`
        margin: 0px 100px 0px 84px;
    `}
`

const BlogLandingDiv = styled.div`
    position: relative;  
`

const BlogContainer = styled.div`
    position: relative;
    z-index: 1;
    top: -40px;
`

const BlogSectionContainer = styled.div`
    width: 500px;

    :hover > a div {
        transition: all 0.5s ease;
            -o-transition: all .5s ease;
        background: ${props => props.theme.colors.fontDark};

    ${media.laptop`
        transform: translateY(62px);
            -webkit-transform: translateY(62px);
            -moz-transform: translateY(62px);
            -o-transform: translateY(62px);
            -ms-transform: translateY(62px);       
    `}}

    :hover > div  {
        transition: all 0.5s ease;
            -o-transition: all 0.5s ease;

        ${media.laptop`
            transform: translateY(-62px);
                -webkit-transform: translateY(-62px);
                -moz-transform: translateY(-62px);
                -o-transform: translateY(-62px);
                -ms-transform: translateY(-62px);       
        `}}

    :hover > a div img {
        transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
        opacity: 0.5;
    }
`

const BlogPostImgDiv = styled.div`
    transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
    width: 89.5%;

    ${media.laptopL`
        width: 95%;
    `}

    ${media.laptopLM`
        width: 96.5%;
    `}

    ${media.laptopLL`
        width: 98%;
    `}
`

const BlogImage = styled.img`
    width: 100%;
    opacity: 1;
    cursor: pointer;
`

const BlogSubtitleDiv = styled.div`
    transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
    color: ${props => props.theme.colors.greenMain};
    font-size: 1.4em;  
    font-weight: 400;    
    padding: 0px 0px 8px 10px;  

    ${media.laptopL`
        padding: 0px 0px 8px 30px;  
    `}
`

const BlogTitleDiv = styled.div`
    transition: all 0.5s ease;
            -o-transition: all 0.5s ease;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-size: 1.5em;
    color: ${props => props.theme.colors.backgroundLight};
    padding: 0px 20px 0px 10px;

    ${media.laptopL`
        padding: 0px 10px 0px 30px;
    `}
`

const CoreDiv = styled.div`
    position: relative;
    top: -18px;
`

const BlogCoreDiv = styled(CoreDiv)`
    top: -12px;
`

const BlogTitlesContainer = styled.div`
    text-decoration: none;
    cursor: pointer;
    padding: 14px 32px 14px 0px;
`

const FlexBlogContainer = styled.div`
    display: grid;
    grid-template-columns: auto auto ;
`

const BlogHeadingP = styled.p`
    position: relative;
    top: 42px;
    left: 32px;  
    font-size: 2.5em; 
    letter-spacing: 0.7px;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    padding-top: 8px;
`

const BlogRoundupDiv = styled.div`
    line-height: 1.12;
    font-size: 1.5em;
    margin-top: -20px;
    padding: 20px 32px 30px 32px;
    text-align: justify;    
`

function BlogPostPreview({ articles }) {
    const mostRecentPostNum = articles.length - 1,
        flattenedAllPostsTagArr = flattenTagArray(articles),
        sortedTagArray = sortByFrequency(flattenedAllPostsTagArr);

    const mostRecentPostObj = articles[mostRecentPostNum],
        mostRecentPostId = getNested(['_id'], mostRecentPostObj),
        mostRecentPostImgUrl = getNested(['image'], mostRecentPostObj),
        mostRecentPostImgAlt = getNested(['image_alt'], mostRecentPostObj),
        mostRecentPostDate = getNested(['published_on'], mostRecentPostObj),
        formatedMostRecentPostDate = moment(mostRecentPostDate).format('MMMM YYYY'),
        mostRecentPostTitle = getNested(['fields_title'], mostRecentPostObj),
        categoryName = getNested(['category_name'], mostRecentPostObj)

    return (
        <FlexLinkContainer>
            <BlogLandingDiv>
                <DotGrid />
                <BlogContainer>
                    <FlexBlogContainer>
                        <RelativeContainerLeft>
                            <BlogCoreDiv>
                                <CoreFeature color='rgba(0, 0, 0, 0.8)' top='-20px' left='10px'
                                    fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                                    letter1='B'
                                    letter2='L'
                                    letter3='O'
                                    letter4='G'
                                    breakpoints={bpsLCFBlog} />
                            </BlogCoreDiv>
                            <Fade duration={1000} up>
                                <BlogHeadingP>Breaking News</BlogHeadingP>
                                <BlogRoundupDiv>
                                    <span>Head over to the </span>
                                    <InlineLinkSpan to='/blog/PostsList' text='blog' color={styleConstants.colors.greenMain} />
                                    <span> {psLanding.blogP} </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[0]} color={styleConstants.colors.greenMain} /><span>, </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[1]} color={styleConstants.colors.greenMain} /><span>, and </span>
                                    <InlineLinkSpan to='/blog/PostsList' text={sortedTagArray[2]} color={styleConstants.colors.greenMain} /><span>.</span>
                                </BlogRoundupDiv>
                                <Fade duration={2000}>
                                    <LinkSpan to='/blog/PostsList'
                                        spanText='MORE STORIES'
                                        fontSize='2.3em'
                                        left='224px'
                                        bottom='0px'
                                        letterSpacing='1px'
                                        breakpoints={bpsLSBP} />
                                </Fade>
                            </Fade>
                        </RelativeContainerLeft>
                        <RelativeContainer>
                            <Fade duration={1500}>
                                <BlogSectionContainer>
                                    <Link to={'/blog/' + mostRecentPostId} id={mostRecentPostId} style={{ textDecoration: 'none', cursor: 'default' }} >
                                        <BlogPostImgDiv>
                                            <BlogImage src={mostRecentPostImgUrl} alt={mostRecentPostImgAlt} />
                                        </BlogPostImgDiv>
                                    </Link>
                                    <div>
                                        <Link to={'/blog/' + mostRecentPostId} id={mostRecentPostId} style={{ textDecoration: 'none', cursor: 'default' }} >
                                            <BlogTitlesContainer>
                                                <BlogSubtitleDiv>&mdash; {formatedMostRecentPostDate}</BlogSubtitleDiv>
                                                <BlogTitleDiv>{mostRecentPostTitle} <span className='labelBP arrowed-in'> {' ' + categoryName} </span> </BlogTitleDiv>
                                            </BlogTitlesContainer>
                                        </Link>
                                    </div>
                                </BlogSectionContainer>
                            </Fade>
                        </RelativeContainer>
                    </FlexBlogContainer>
                </BlogContainer>
            </BlogLandingDiv>
        </FlexLinkContainer>
    )
}

export default withContext(BlogPostPreview);