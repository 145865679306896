import { css } from 'styled-components/macro'

const sizes = {
  phoneS: 320,
  phoneSM: 340,
  phoneM: 370,
  phoneL: 414,
  tablet: 620,
  laptop: 1000,
  laptopM: 1100,
  laptopL: 1200,
  laptopLM: 1300,
  laptopLL: 1400,
  desktop: 1800,
  desktopL: 2200
}

// iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  const emSize = sizes[label] / 16
  acc[label] = (...args) => css`
      @media (min-width: ${emSize}em) {
        ${css(...args)};
      }
    `
  return acc
}, {})

export default media;


