import React from 'react'
import styled from 'styled-components/macro'
import { withBps } from 'react-bps'
import Fade from 'react-reveal'

import { bpsFCSI, bpsFCCF } from '../../Shared/Constants/breakpoints'
import { SocialHrefs, SocialAltStrings } from '../../Shared/Constants/paragraphStrings'

import CoreFeature from '../../Shared/CoreFeature'
import SocialIcon from '../../Shared/SocialIcon'
import LinkSpan from '../../Shared/LinkSpan'
import media from '../../../theme/DeviceWidth'

import FacebookIconBlack from '../../../assets/icons/facebook_black.svg'
import FacebookIconGreen from '../../../assets/icons/facebook_green.svg'
import FacebookIconWhite from '../../../assets/icons/facebook_white.svg'
import InstagramIconBlack from '../../../assets/icons/instagram_black.svg'
import InstagramIconGreen from '../../../assets/icons/instagram_green.svg'
import InstagramIconWhite from '../../../assets/icons/instagram_white.svg'

const FooterContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    background: ${props => props.dark ? props.theme.colors.fontDark : props.theme.colors.backgroundLight};
    color: ${props => props.dark ? props.theme.colors.backgroundLight : props.theme.colors.fontDark};
    margin-top: 10px;

    ${media.tablet`
        display: flex;
        margin-top: 0px
        padding-top: 20px;
    `}

    ${media.laptop`
        padding-top: 30px;
    `}
`

const FlexContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

    ${media.tablet`
        text-align: right;
        margin: 6px 0px 10px 0px;
    `}

    ${media.laptop`
        white-space: nowrap;
        padding-left: 10%;
        margin: 6px 0px 14px 0px;
    `}

    ${media.laptopM`
        padding-left: 16%;
        margin: 6px 0px 16px 0px;
    `}

    ${media.laptopL`
        padding-left: 20%;
        margin: 6px 0px 18px 0px;
    `}

    ${media.laptopLM`
        padding-left: 24%;
        margin: 6px 0px 20px 0px;
    `}
`

const FeatureDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.tablet`
        max-width: 54%;
        padding-left: 10px;
    `}

    ${media.laptop`
        max-width: 500px;
        padding-left: 5%;
    `}

    ${media.laptopL`
        padding-left: 6.5%;
    `}            
`

const CoreFeatureDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    top: -22px;

    ${media.tablet`
        top: -34px;
        left: -4px;
    `}

    ${media.laptop`
        top: -44px;
        left: -6px;
    `}
`

const FeatureDescriptionDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    letter-spacing: 0.5px;
    margin-top: -20px;
    padding: 30px 18px 24px 20px;

    ${media.phoneS`
        font-size: 1.7em;
    `}
      
    ${media.phoneM`
        font-size: 1.8em;
    `}
          
    ${media.phoneL`
        font-size: 1.9em;
    `}
          
    ${media.tablet`
        font-size: 2em;
        padding-top: 40px; 
    `}

    ${media.laptop`
        font-size: 2.1em;    
    `}
`

const SocialIconsDiv = styled.div`
    padding: 0px 0px 12px 20px;
`

const HorizSpacer = styled.div`
    display: inline !important;
    padding: 0px 14px 0px 0px;
`

const FooterInfoDiv1 = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    font-size: 1.1em;

    ${media.phoneS`
        padding: 0px 0px 10px 20px;
    `}
          
    ${media.tablet`
        font-size: 1.2em;    
    `}

    ${media.laptop`
        font-size: 1.3em;    
    `}    
`

const FooterInfoDiv2 = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    font-size: 1.1em;

    ${media.phoneS`
        padding: 0px 0px 10px 20px;
    `}
          
    ${media.tablet`
        font-size: 1.2em;    
    `}

    ${media.laptop`
        font-size: 1.3em;    
    `}
`

const CopyDiv = styled.div`
    font-size: 0.8em;
`

function Footer({ dark }) {
    let d = new Date(),
        currentYear = d.getFullYear();
    return (
        <FooterContainer dark={dark}>
            <FeatureDiv>
                <Fade duration={2000}>
                    <CoreFeatureDiv>
                        <CoreFeature color={dark ? 'black' : 'white'} top='-16px' left='10px'
                            fontWeight='500' fontSize='3em' letterSpacing='.15em' lineHeight='3em'
                            letter1='C'
                            letter2='O'
                            letter3='N'
                            letter4='T'
                            letter5='A'
                            letter6='C'
                            letter7='T'
                            breakpoints={bpsFCCF} />
                    </CoreFeatureDiv>
                    <FeatureDescriptionDiv>
                        Tell us about your <LinkSpan to='/about/agency' spanText='NEW PROJECT' />, meet the<span> </span> <LinkSpan to='/about/agency' spanText='TEAM' />, and connect with us across all socials.
                </FeatureDescriptionDiv>
                </Fade>
            </FeatureDiv>
            <FlexContainer>
                <Fade duration={2000}>
                    <SocialIconsDiv>
                        <SocialIcon height='42px' width='42px'
                            to={SocialHrefs.FbHref}
                            src={dark ? FacebookIconWhite : FacebookIconBlack}
                            srcHover={FacebookIconGreen}
                            alt={SocialAltStrings.FbAlt}
                            breakpoints={bpsFCSI}>
                        </SocialIcon>
                        <HorizSpacer></HorizSpacer>
                        <SocialIcon height='42px' width='42px'
                            to={SocialHrefs.InstaHref}
                            src={dark ? InstagramIconWhite : InstagramIconBlack}
                            srcHover={InstagramIconGreen}
                            alt={SocialAltStrings.InstaAlt}
                            breakpoints={bpsFCSI}>
                        </SocialIcon>
                    </SocialIconsDiv>
                    <FooterInfoDiv1>
                        <div>716-218-3015</div>
                        <div>create@jpsignum.com</div>
                    </FooterInfoDiv1>
                    <FooterInfoDiv2>
                        <CopyDiv> &copy; {currentYear} JP Signum L.L.C. All Rights Reserved.</CopyDiv>
                    </FooterInfoDiv2>
                </Fade>
            </FlexContainer>
        </FooterContainer>
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(Footer);