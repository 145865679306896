import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

const JpMobileMenuIcon = styled.img`
    position: relative;
    height: ${props => props.height};
    width: ${props => props.width};
    top: ${props => props.top};
    left: ${props => props.left};
    display: ${props => props.display};
`

function JpIcon({ source, mouseOver, mouseOut, ...rest }) {
    return (
        <Link to='/'>
            <JpMobileMenuIcon {...rest} src={source} alt='JP Signum Icon'
                onMouseOver={e => (e.currentTarget.src = mouseOver)}
                onMouseOut={e => (e.currentTarget.src = mouseOut)} />
        </Link>
    )
}

export default JpIcon;