export const memberDetails ={
    cdFirstName: "patrick",
    cdLastName: "morgan",
    cdTitle: "creative director",
    cdBlurb: "Patrick is a full stack engineer specializing in modern mobile and web applications. As our creative director you can also find him managing projects across the design and marketing teams.",
    cdBio: "Founder, creative director, engineer, marketeer, designer. No matter what hat he is wearing Patrick’s creativity, problem solving, and passion are always on the forefront. Having went to Syracuse University for Biochemistry he cut his teeth doing research for DARPA as part of Baylor Medicine’s Orthopedic program in Houston, Texas, before transitioning into project management for some of the largest Biotech and Pharmaceutical companies in the world. Unique problem solving abilities coupled with a passion for learning and teaching others computer science have shaped his experiences over the years. He has been building brands first as an independent entrepreneur then across both global brands & grassroots startups from New York City to Silicon Slopes.",
    cdTwitter: "//www.twitter.com/jp_Signum",
    cdBehance: "//www.behance.net/jp_signum",
    cdLinkedIn: "//www.linkedin.com/in/pjmorgan/",
    cdGithub: "//www.github.com/jp-signum",
    cdId: "patrick-morgan",
    cdMetaDescription: "Patrick is a full stack engineer specializing in modern mobile and web applications. As our creative director you can also find him managing projects across the design and marketing teams.",
    cdMetaKeywords: "Full Stack Engineer, Computer Science, Programmer, React Developer, React, Full Stack, Back End, Front End, Developer, Engineer, Engineering, Agency, Creative Director, Marketing, Digital Marketing, Advertising, Patrick Morgan, JP Signum, Design, Designer, Graphic Design, Graphic Designer"
}