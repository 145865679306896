import React from 'react'
import ReactDom from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax'
import { ThemeProvider } from 'styled-components/macro'

import { AppContextProvider } from '../src/components/AppContext'

import mainThemeConstants from './theme/mainThemeConstants'
import ScrollToTop from '../src/components/ScrollToTop'
import App from './components/App/App'
import GA from './utils/GoogleAnalytics'


ReactDom.render(
    <AppContextProvider>
        <Router>
            <ScrollToTop>
                <ParallaxProvider>
                    <ThemeProvider theme={mainThemeConstants}>
                        {GA.init() && <GA.RouteTracker />}
                        <App />
                    </ThemeProvider>
                </ParallaxProvider>
            </ScrollToTop>
        </Router>
    </AppContextProvider>,
    document.getElementById("root")
)