import React from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import media from '../../../../theme/DeviceWidth'

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.tablet`
       padding-top: 40px;
    `}

    ${media.laptop`
        margin: 40px 0px 0px 0px;
        padding: 0% 8%;
    `}

    ${media.laptopM`
        padding: 0% 10%;
    `}

    ${media.laptopL`
        padding: 0% 11%;
    `}
`

const HeaderDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;

    ${media.tablet`
        display: grid;
        grid-template-columns: 44% 56%;
    `}
`

const BlogTitleDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    top: 14px;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    letter-spacing: .08em;
    font-weight: 400;
    font-size: 1.6em;
    padding: 70px 20px 30px 20px;
      
    ${media.phoneM`
        font-size: 1.7em;
        padding: 80px 20px 30px 20px;
    `}
          
    ${media.phoneL`
        font-size: 1.8em;   
    `}
          
    ${media.tablet`
        font-size: 1.9em;    
    `}
      
    ${media.laptop`
        font-size: 2.2em;   
        padding: 90px 20px 30px 20px;   
    `}
`

const FeaturedImg = styled.img`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    width: 100%;

    ${media.tablet`
        margin-top: 100px;
    `}

    ${media.laptop`
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    `}
`

const Headline = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    background: rgb(0, 0, 0, 0.1);
    border-left: ${props => props.theme.colors.greenMain} 2px solid;
    line-height: 1.1 !important;
    font-size: 1.15em !important;
    text-align: justify;
    padding: 14px 10px 14px 18px;
    margin-top: -4px;
      
    ${media.phoneM`
        font-size: 1.2em !important;
        padding: 14px 30px 14px 18px;
    `}
          
    ${media.phoneL` 
        font-size: 1.3em !important;  
        padding: 18px 30px 18px 18px;  
    `}
          
    ${media.tablet`
        position: absolute; 
        bottom: 4px;
    `}
      
    ${media.laptop`
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    `}
`

function BPHead({ imgUrl, imgAlt, title, categoryName, headline }) {
    return (
        <RelativeContainer>
            <Fade duration={2000}>
                <HeaderDiv>
                    <div>
                        <FeaturedImg src={imgUrl} alt={imgAlt} />
                    </div>
                    <div>
                        <BlogTitleDiv>{title}<span className='labelSP arrowed-in'>{' ' + categoryName}</span></BlogTitleDiv>
                        <Headline>{headline}<span>.</span> </Headline>
                    </div>
                </HeaderDiv>
            </Fade>
        </RelativeContainer>
    )
}

export default BPHead;
