import React from 'react'
import Particles from 'react-particles-js'
import { withBps } from 'react-bps'

function Polygon({ height, params }) {
    return (
        <Particles height={height} params={params} />
    )
}

export default withBps({ mobileFirst: true, propName: 'breakpoints' })(Polygon);


