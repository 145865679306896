import React, { Component } from 'react'
import styled from 'styled-components/macro'
import Fade from 'react-reveal'

import { countArrayValueInstances, listAllProperties, flattenTagArray, filterByTag } from '../../../Shared/Constants/helperFunctions'
import { withContext } from '../../../AppContext'

import media from '../../../../theme/DeviceWidth'
import PostPreview from './PostPreview'
import PostsList from './PostsList'

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    margin-top: 130px;

    ${media.phoneM`
        margin-top: 128px; 
    `}

    ${media.phoneL`
        margin-top: 130px; 
    `}

    ${media.tablet`
        display: grid;
        grid-template-columns: 200px auto;
        margin-top: 110px; 
    `}
`

const ScrollMenu = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    overflow: auto;
    white-space: nowrap;
    z-index: 2;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .06em; 
    padding: 0px 22px 0px 20px;
    margin-top: 110px;

    ${media.phoneS`
        font-size: 1.4em;
    `}
      
    ${media.phoneM`
        font-size: 1.5em;
    `}
          
    ${media.phoneL`
        font-size: 1.6em;  
    `}
          
    ${media.tablet`
        font-size: 1.7em;
        overflow: visible;
        white-space: normal;  
    `}
      
    ${media.laptop`
        font-size: 1.6em;  
    `}
      
    ${media.laptopL`
        padding: 0px 22px 0px 34px;      
    `}
`

const ScrollMenuItem = styled.div`
    transition: color 0.6s ease;
        -o-transition: color 0.6s ease;
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding: 0px 20px 14px 0px;

    :hover{
        color: ${props => props.theme.colors.greenMain};
    }

    ${media.tablet`
        width: 100%;
        padding: 0px 0px 4px 0px;  
    `}
`

const Num = styled.sup`
    position: relative;
    top: -4px;
    font-size: .6em;
    line-height: 2em;
`

const TagListDiv = styled.div`
    position: relative;
`

class TagMenu extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allSelected: true,
            firstSelected: false,
            secondSelected: false,
            thirdSelected: false,
            fourthSelected: false,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        }
    }

    selectAllPosts = () => {
        this.setState({
            allSelected: true,
            firstSelected: false,
            secondSelected: false,
            thirdSelected: false,
            fourthSelected: false,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        })
    }

    selectFirstTag = () => {
        this.setState({
            allSelected: false,
            firstSelected: true,
            secondSelected: false,
            thirdSelected: false,
            fourthSelected: false,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        })
    }

    selectSecondTag = () => {
        this.setState({
            allSelected: false,
            firstSelected: false,
            secondSelected: true,
            thirdSelected: false,
            fourthSelected: false,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        })
    }

    selectThirdTag = () => {
        this.setState({
            allSelected: false,
            firstSelected: false,
            secondSelected: false,
            thirdSelected: true,
            fourthSelected: false,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        })
    }

    selectFourthTag = () => {
        this.setState({
            allSelected: false,
            firstSelected: false,
            secondSelected: false,
            thirdSelected: false,
            fourthSelected: true,
            fifthSelected: false,
            sixthSelected: false,
            seventhSelected: false
        })
    }


    render() {
        const { articles } = this.props,
            flattenedAllPostsTagArr = flattenTagArray(articles),
            testCount = countArrayValueInstances(flattenedAllPostsTagArr),
            AllTags = listAllProperties(testCount);

        const tagListFirstName = AllTags[0],
            tagListSecondName = AllTags[1],
            tagListThirdName = AllTags[2];

        const TagListFirstNumber = testCount[tagListFirstName],
            TagListSecondNumber = testCount[tagListSecondName],
            TagListThirdNumber = testCount[tagListThirdName];

        const AllPostsNumber = Object.keys(articles).length;

        const AllPosts = articles.map(article => {
            return (
                <PostPreview
                    key={article._id}
                    article={article}
                    id={article._id} />
            )
        })

        const FirstTagFilterPostsArr = filterByTag(articles, tagListFirstName),
            FirstTagFilterPosts = FirstTagFilterPostsArr.map(article => {
                return (
                    <PostPreview
                        key={article._id}
                        article={article}
                        id={article._id} />
                )
            })

        const SecondTagFilterPostsArr = filterByTag(articles, tagListSecondName),
            SecondTagFilterPosts = SecondTagFilterPostsArr.map(article => {
                return (
                    <PostPreview
                        key={article._id}
                        article={article}
                        id={article._id} />
                )
            })

        const ThirdTagFilterPostsArr = filterByTag(articles, tagListThirdName),
            ThirdTagFilterPosts = ThirdTagFilterPostsArr.map(article => {
                return (
                    <PostPreview
                        key={article._id}
                        article={article}
                        id={article._id} />
                )
            })
 
        return (
            <RelativeContainer>
                <TagListDiv>
                    <Fade duration={2000}>
                        <ScrollMenu>
                            <ScrollMenuItem onClick={() => this.selectAllPosts()}
                                className={this.state.allSelected ? 'isSelected' : 'isNotSelected'}>
                                <span>All</span>
                                <Num>{AllPostsNumber}</Num>
                            </ScrollMenuItem>
                            <ScrollMenuItem onClick={() => this.selectFirstTag()}
                                className={this.state.firstSelected ? 'isSelected' : 'isNotSelected'}>
                                <span>{tagListFirstName}</span>
                                <Num>{TagListFirstNumber}</Num>
                            </ScrollMenuItem>
                            <ScrollMenuItem onClick={() => this.selectSecondTag()}
                                className={this.state.secondSelected ? 'isSelected' : 'isNotSelected'}>
                                <span>{tagListSecondName}</span>
                                <Num>{TagListSecondNumber}</Num>
                            </ScrollMenuItem>
                            <ScrollMenuItem onClick={() => this.selectThirdTag()}
                                className={this.state.thirdSelected ? 'isSelected' : 'isNotSelected'}>
                                <span>{tagListThirdName}</span>
                                <Num>{TagListThirdNumber}</Num>
                            </ScrollMenuItem>
                        </ScrollMenu>
                    </Fade>
                </TagListDiv>
                <div>
                    <Fade duration={3000}>
                        <PostsList menuState={this.state}
                            allPosts={AllPosts}
                            firstTagPosts={FirstTagFilterPosts}
                            secondTagPosts={SecondTagFilterPosts}
                            thirdTagPosts={ThirdTagFilterPosts} />
                    </Fade>
                </div>
            </RelativeContainer>
        )
    }
}

export default withContext(TagMenu);