import React, { useEffect } from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import styled from 'styled-components'

import GlobalStyle from '../../theme/GlobalStyle'
import Home from './Home/index'
import About from './About/Agency/index'
import TeamMember from './About/Team/index'
import PostsLists from './Blog/List/index'
import SingularPost from './Blog/SingularPost/index'
import CaseStudies from './CaseStudies/index'
import Services from './Services/index'
import Strategy from './Services/Strategy'
import Branding from './Services/Branding'
import Web from './Services/Web'
import Software from './Services/Software'

const OverflowFixContainer = styled.div`
    overflow: hidden;
`

function App() {
    // fake authentication Promise
    const authenticate = () => {
        return new Promise(resolve => setTimeout(resolve, 2250))
    }

    useEffect(() => {
        authenticate().then(() => {
            const ele = document.getElementById('ipl-progress-indicator')
            if (ele) {
                ele.classList.add('available')
                setTimeout(() => {
                    ele.outerHTML = ''
                }, 2000)
            }
        })
    }, []);

    return (
        <OverflowFixContainer>
            <GlobalStyle />
            <Switch>
                <Route
                    exact path='/'
                    component={Home} />
                <Route
                    path='/about/agency'
                    component={About} />
                <Route
                    path='/about/team/:id'
                    component={TeamMember} />
                <Route
                    path='/blog/postslist'
                    component={PostsLists} />
                <Route
                    path='/blog/:id'
                    component={SingularPost} />
                <Route
                    path='/caseStudies'
                    component={CaseStudies} />
                <Route
                    path='/services'
                    component={Services} />
                <Route
                    path='/strategy'
                    component={Strategy} />
                <Route
                    path='/branding'
                    component={Branding} />
                <Route
                    path='/web'
                    component={Web} />
                <Route
                    path='/software'
                    component={Software} />
            </Switch>
        </OverflowFixContainer>
    )
}


export default withRouter(App);