import React from 'react'
import styled from 'styled-components/macro'

import { memberDetails } from '../memberDetails'
import { MobileNavDivBeforeWhite } from '../../../Shared/Navigation/navStyleConstants'
import { bpsSN } from '../../../Shared/Constants/breakpoints'

import FLC from '../../../Shared/FLC'
import BlogPostPreview from '../BlogPostPreview'
import Navigation from '../../../Shared/Navigation/index'
import TeamMemberFull from './TeamMemberFull'
import Footer from '../../Footer/index'
import styleConstants from '../../../../theme/styleConstants'

import CreativeDirectorImgPJM from '../../../../assets/images/pjm_author1.jpg'
import jpBlack from '../../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../../assets/icons/jp_mobile_icon_green.svg'

const RelativeContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    overflow: hidden;
    background: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};
    width: 100%;
`

function TeamMember({ match: { params: id } }) {
    return (
        <RelativeContainer>
            <Navigation height='44px' width='44px' top='17px' left='22px'
                color={styleConstants.colors.fontDark}
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName='bm-burger-bars-DARK'
                breakpoints={bpsSN} />
            <TeamMemberFull id={id}
                firstName={memberDetails.cdFirstName}
                lastName={memberDetails.cdLastName}
                bio={memberDetails.cdBio}
                title={memberDetails.cdTitle}
                member_id={memberDetails.cdId}
                behance={memberDetails.cdBehance}
                github={memberDetails.cdGithub}
                linkedIn={memberDetails.cdLinkedIn}
                twitter={memberDetails.cdTwitter}
                src={CreativeDirectorImgPJM}
                alt='Patrick Morgan Creative Director' />
            <FLC />
            <BlogPostPreview />
            <Footer dark='true'/>
        </RelativeContainer>
    )
}

export default TeamMember;