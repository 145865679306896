import React from 'react'
import styled from 'styled-components/macro'
import { Helmet } from 'react-helmet'

import { MetaBlogList } from '../../../Shared/Constants/meta'
import { MobileNavDivBeforeWhite } from '../../../Shared/Navigation/navStyleConstants'
import { bpsSN, bpsBL } from '../../../Shared/Constants/breakpoints'

import media from '../../../../theme/DeviceWidth'
import Navigation from '../../../Shared/Navigation/index'
import CoreFeature from '../../../Shared/CoreFeature'
import TagMenu from './TagMenu'
import FLC from '../../../Shared/FLC'
import styleConstants from '../../../../theme/styleConstants'
import Footer from '../../Footer/index'

import jpBlack from '../../../../assets/icons/jp_mobile_icon_black.svg'
import jpGreen from '../../../../assets/icons/jp_mobile_icon_green.svg'

const BlogListContainer = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    background-color: ${props => props.theme.colors.backgroundLight};
    color: ${props => props.theme.colors.fontDark};
    overflow: hidden;
    width: 100%;

    ${media.tablet`
        margin: 20px 0px 0px 0px;
    `}

    ${media.laptop`
        margin: 30px 0px 0px 0px;
    `}
`
const BlogCoreDiv = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`

const BlogHeadingDiv = styled.div`
    transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
    position: relative;
    top: 98px;
    text-align: center;
    text-transform: uppercase;
    font-family: 'BebasNeue', bebas-neue, sans-serif;
    font-weight: 400;
    letter-spacing: .2em;

    ${media.phoneS`
        font-size: 1.5em;
    `}
      
    ${media.phoneM`
        font-size: 1.8em;
        font-weight: 500;
    `}
          
    ${media.phoneL`
        font-size: 2em; 
    `}
          
    ${media.tablet`
        font-size: 2.4em;    
    `}
`

function BlogList() {
    return (
        <BlogListContainer>
            <Helmet titleTemplate='Blog | %s'>
                <title>{MetaBlogList.title}</title>
                <meta name='description' content={MetaBlogList.description}></meta>
                <meta name='keywords' content={MetaBlogList.keywords}></meta>
            </Helmet>
            <Navigation height='44px' width='44px' top='17px' left='22px'
                color={styleConstants.colors.fontDark}
                mobileNavBefore={MobileNavDivBeforeWhite}
                source={jpBlack}
                mouseIn={jpGreen}
                mouseOut={jpBlack}
                burgerBarClassName='bm-burger-bars-DARK'
                breakpoints={bpsSN} />
            <BlogCoreDiv>
                <CoreFeature color='rgba(255, 255, 255, 1.0)' fontWeight='700' fontSize='4.2em'
                    letterSpacing='.28em' lineHeight='2.35em' top='40px' left='52.5%'
                    transform='translateX(-50%)'
                    letter1='B'
                    letter2='L'
                    letter3='O'
                    letter4='G'
                    breakpoints={bpsBL} />
                <BlogHeadingDiv>FRESH NEWS</BlogHeadingDiv>
            </BlogCoreDiv>
            <TagMenu />
            <FLC />
            <Footer />
        </BlogListContainer>
    )
}

export default BlogList;