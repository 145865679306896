import React from 'react'
import styled from 'styled-components/macro'

import SocialIcon from '../../Shared/SocialIcon'
import Fade from 'react-reveal'
import media from '../../../theme/DeviceWidth'

import LinkedInB from '../../../assets/icons/linkedin_black.svg'
import LinkedInG from '../../../assets/icons/linkedin_green.svg'
import GithubB from '../../../assets/icons/github_black.svg'
import GithubG from '../../../assets/icons/github_green.svg'
import TwitterB from '../../../assets/icons/twitter_black.svg'
import TwitterG from '../../../assets/icons/twitter_green.svg'
import BeehanceB from '../../../assets/icons/behance_black.svg'
import BeehanceG from '../../../assets/icons/behance_green.svg'

const MemberSocial = styled.div`
    position: relative;
    left: 20px;
    padding: 10px 0px 20px 0px;

    ${media.tablet`
        padding: 0px 0px 0px 0px;
    `}
`

function MemberSocials({ linkedInUri, githubUri, twitterUri, behanceUri }) {
    return (
        <Fade duration={2000}>
            <MemberSocial>
                <SocialIcon
                    to={linkedInUri}
                    src={LinkedInB}
                    srcHover={LinkedInG}
                    alt='Linked In Icon'
                    height='24px'
                    width='24px'
                    padding='0px 16px 0px 0px'
                    breakpoints={{
                        410: {
                            to: `${linkedInUri}`,
                            height: '28px',
                            width: '28px',
                            padding: '0px 18px 0px 0px'
                        },
                        620: {
                            to: `${linkedInUri}`,
                            height: '32px',
                            width: '32px',
                            padding: '0px 20px 0px 0px'
                        }
                    }}>
                </SocialIcon>
                <SocialIcon
                    to={githubUri}
                    src={GithubB}
                    srcHover={GithubG}
                    alt='Github Icon'
                    height='24px'
                    width='24px'
                    padding='0px 16px 0px 0px'
                    breakpoints={{
                        410: {
                            to: `${githubUri}`,
                            height: '28px',
                            width: '28px',
                            padding: '0px 18px 0px 0px'
                        },
                        620: {
                            to: `${githubUri}`,
                            height: '32px',
                            width: '32px',
                            padding: '0px 20px 0px 0px'
                        }
                    }}>
                </SocialIcon>
                <SocialIcon
                    to={twitterUri}
                    src={TwitterB}
                    srcHover={TwitterG}
                    alt='Twitter Icon'
                    height='27px'
                    width='27px'
                    top='2px'
                    padding='0px 16px 0px 0px'
                    breakpoints={{
                        410: {
                            to: `${twitterUri}`,
                            height: '28px',
                            width: '28px',
                            padding: '0px 18px 0px 0px'
                        },
                        620: {
                            to: `${twitterUri}`,
                            height: '32px',
                            width: '32px',
                            padding: '0px 20px 0px 0px'
                        }
                    }}>
                </SocialIcon>
                <SocialIcon
                    to={behanceUri}
                    src={BeehanceB}
                    srcHover={BeehanceG}
                    alt='Behance Icon'
                    height='32px'
                    width='32px'
                    top='5px'
                    padding='0px 16px 0px 0px'
                    breakpoints={{
                        410: {
                            to: `${behanceUri}`,
                            height: '36px',
                            width: '36px',
                            padding: '0px 18px 0px 0px'
                        },
                        620: {
                            to: `${behanceUri}`,
                            height: '40px',
                            width: '40px',
                            padding: '0px 20px 0px 0px'
                        }
                    }}>
                </SocialIcon>
            </MemberSocial>
        </Fade>
    )
}

export default MemberSocials;