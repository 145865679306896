import React from 'react'
import styled from 'styled-components/macro'

const Icon = styled.img`
    position: relative;
    height: ${props => props.height};
    width: ${props => props.width};
    left: ${props => props.left};
    top: ${props => props.top};
    z-index: ${props => props.zIndex};
`

function ServiceIcon(props) {
    return (
        <Icon
            src={props.src}
            alt={props.alt}
            height={props.height}
            width={props.width}
            left={props.left}
            top={props.top}
            zIndex={props.zIndex}
            className={props.className}/>
    )
}

export default ServiceIcon;