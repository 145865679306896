import React from 'react'
import Fade from 'react-reveal'

function PostsList({
    allPosts, firstTagPosts, secondTagPosts, thirdTagPosts,
    menuState: { allSelected, firstSelected, secondSelected, thirdSelected }
}) {
    if (allSelected === true) {
        return (
            <div>
                <div>{allPosts}</div>
            </div>
        )
    } else if (firstSelected) {
        return (
            <div>
                <Fade duration={2000}>
                    <div>{firstTagPosts}</div>
                </Fade>
            </div>
        )
    } else if (secondSelected) {
        return (
            <div>
                <Fade duration={2000}>
                    <div>{secondTagPosts}</div>
                </Fade>
            </div>
        )
    } else if (thirdSelected) {
        return (
            <div>
                <Fade duration={2000}>
                    <div>{thirdTagPosts}</div>
                </Fade>
            </div>
        )
    }  
}

export default PostsList;